import axios from 'axios';
import {apiService} from "../../services/api.service";
import {extractData, handleResponse} from "../../helpers/apiResponseHelper";
import {ChargingPricingForm} from "./models";

async function findChargingPricings() {
    return axios.get(`${apiService.getApiUrl()}/charging/pricings/admin`)
        .then(handleResponse).then(extractData);
}

async function postChargingPricing(request: ChargingPricingForm) {
    return axios.post(`${apiService.getApiUrl()}/charging/pricings/admin`,
        {
            ...request
        }).then(handleResponse).then(extractData);
}

async function putChargingPricing(id: number, request: ChargingPricingForm) {
    return axios.put(`${apiService.getApiUrl()}/charging/pricings/admin/${id}`,
        {
            ...request
        }).then(handleResponse).then(extractData);
}

async function deleteChargingPricing(id: number) {
    return axios.delete(`${apiService.getApiUrl()}/charging/pricings/admin/${id}`)
        .then(handleResponse);
  }

export const ChargingPricingsService = {
    postChargingPricing,
    findChargingPricings,
    putChargingPricing,
    deleteChargingPricing
};