import axios from "axios";
import { downloadFileWithPresignedUrl, extractData, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";

async function findProductionTablesForAgreement(contractNumber: string) {
  return axios.get(apiService.getApiUrl() + `/electricity/production-tables/admin/agreement/${contractNumber}`)
    .then(handleResponse).then(extractData);
}

async function findProductionTablesForCustomer(customerId: number) {
  return axios.get(apiService.getApiUrl() + `/electricity/production-tables/admin/customer/${customerId}`)
    .then(handleResponse).then(extractData);
}

async function getProductionTableFile(productionTableId: number, isCorrection? : boolean) {
  return axios.get(apiService.getApiUrl() + `/electricity/production-tables/admin/${productionTableId}/file?isCorrection=${isCorrection}`)
    .then(handleResponse).then((res) => downloadFileWithPresignedUrl(res.data));
}

export const ElectricityProductionTablesService = {
  findProductionTablesForAgreement,
  findProductionTablesForCustomer,
  getProductionTableFile
};