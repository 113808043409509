import React from "react";
import { CHARGING_AGREEMENT_STATUS_MAP, ChargingAgreementStatus, ChargingAgreement, ChargingAgreementSortFields } from "./models";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import Pagination from "rc-pagination";
import {locale} from "../../common/table/paginatorConfig";
import { SortIcons } from "components/SortIcons/SortIcons";
import { roundNumber } from "helpers";
import { IPageResponse, SortDirection } from "common/interfaces/IPageResponse";
import { ModalType } from "common/common-models";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface ChargingAgreementTableProps {
    chargingAgreements: IPageResponse<ChargingAgreement>;
    onPageChange: (page: number, size: number) => void;
    onSort: (sortField: ChargingAgreementSortFields) => void;
    sortField: ChargingAgreementSortFields;
    sortDirection: SortDirection;
    openChargingAgreementModal: (modalType: ModalType, chargingAgreement: ChargingAgreement) => void;
}

export function ChargingAgreementsTable(props: ChargingAgreementTableProps) {
    const chargingAgreements: Array<ChargingAgreement> = props.chargingAgreements.content;
    const pageResponse = props.chargingAgreements;

    const chargingAgreementTableRows = chargingAgreements.map((chargingAgreement: ChargingAgreement) =>
        <tr key={'row-' + chargingAgreement.id}>
            <td className="line-height-1">
                {chargingAgreement.customerName}<br />
            </td>
            <td className="text-nowrap">
                {chargingAgreement.contractNumber}
            </td>
            <td>
                {chargingAgreement.startDate ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(chargingAgreement.startDate)) : ""}
            </td>
            <td>
                {chargingAgreement.endDate ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(chargingAgreement.endDate)) : 'Tähtajatu'}
            </td>            
            <td>
                { roundNumber(chargingAgreement.chargingServiceMarginalCentsKwh, 4)}
            </td>
            <td>
                {chargingAgreement.meteringPoint.eic}
            </td>
            <td>
                {chargingAgreement.externalChargingStationId}
            </td>
            <td>
                {CHARGING_AGREEMENT_STATUS_MAP[chargingAgreement.status]}
            </td>
            <td>
                {chargingAgreement.dateCreated ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(chargingAgreement.dateCreated)) : ""}
            </td>
            <td>
                <div className="row__actions">
                {
                        (chargingAgreement.status === ChargingAgreementStatus.ACTIVE) && (
                            <OverlayTrigger
                                    delay={250}
                                    overlay={
                                        <Tooltip id={`update-package-tooltip-${chargingAgreement.id}`}>
                                            <p style={{margin: '3px'}}>Muuda paketti</p>
                                        </Tooltip>
                                    } 
                                    placement={"left"}
                            >
                                <Button 
                                    size="sm"                             
                                    variant="secondary"
                                    className="d-flex align-items-center btn-outline-icon" 
                                    onClick={() => props.openChargingAgreementModal(ModalType.EDIT, chargingAgreement)}
                                    >
                                        <i className="icon--16 my-2 icon__edit--outline-mask"/>
                                </Button>
                            </OverlayTrigger>
                        )
                    }
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
            <tr>
                <th>Klient</th>
                <th>Lepingu number</th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(ChargingAgreementSortFields.START_DATE)}>
                        <span>Alguskuupäev</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={ChargingAgreementSortFields.START_DATE} />
                    </div>
                </th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(ChargingAgreementSortFields.END_DATE)}>
                        <span>Lõppkuupäev</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={ChargingAgreementSortFields.END_DATE} />
                    </div>
                </th>                
                <th>Teenuse marginaal, s/kWh</th>
                <th>Mõõtepunkt</th>
                <th>Jaama NOOM id</th>
                <th>Staatus</th>
                <th className="sort-down-up-hover">
                    <div className="d-flex align-items-center"
                        onClick={() => props.onSort(ChargingAgreementSortFields.DATE_CREATED)}>
                        <span>Leping loodud</span>
                        <SortIcons
                            sortedByField={props.sortField}
                            sortDirection={props.sortDirection}
                            currentSortColumn={ChargingAgreementSortFields.DATE_CREATED} />
                    </div>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {chargingAgreementTableRows}
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={9}>
                    <div className="pagination-wrapper">
                        <Pagination
                            locale={locale}
                            current={pageResponse.number + 1}
                            total={pageResponse.totalElements}
                            pageSize={pageResponse.size}
                            prefixCls={"pagination"}
                            onChange={props.onPageChange}
                            showSizeChanger={false}
                            hideOnSinglePage={true}
                        />
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
    )
}