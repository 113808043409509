import axios from "axios";
import { extractData, handleApiException, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";
import { EditAdvertisementModalForm } from "./modals/EditAdvertisementModal";
import { LanguageOption } from "common/common-models";

async function getAdvertisements(request: any) {
  return axios.get(apiService.getApiUrl() + '/promotions/admin', {
      params: request
  }).then(handleResponse).then(extractData);
}

async function createAdvertisement(request: EditAdvertisementModalForm) {
  return axios.post(apiService.getApiUrl() + '/promotions/admin', {
    ...request
  }).then(handleResponse).then(extractData);
}

async function updateAdvertisement(request: EditAdvertisementModalForm, advertisementId: number) {
  return axios.put(apiService.getApiUrl() + `/promotions/admin/${advertisementId}`, {
    ...request
  }).then(handleResponse).then(extractData);
}

async function uploadAdvertisementsImage(file: File, advertisementId: number, lang: LanguageOption) {
  const formData = new FormData();
  
  formData.append("file", file)

  return axios({
    method: 'POST',
    url: apiService.getApiUrl() + `/promotions/admin/${advertisementId}/file?lang=${lang}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }).then(handleResponse).then(extractData);
}

async function getAdvertisementsImage(id: number, lang: LanguageOption) {
  return axios.get(apiService.getApiUrl() + `/promotions/admin/${id}/file?lang=${lang}`)
    .then(handleResponse);
}

async function deleteAdvertisement(advertisementId: number) {
  return axios.delete(apiService.getApiUrl() + `/promotions/admin/${advertisementId}`)
      .catch(handleApiException);
}

export const AdvertisementService = {
  getAdvertisements,
  createAdvertisement,
  updateAdvertisement,
  uploadAdvertisementsImage,
  getAdvertisementsImage,
  deleteAdvertisement
}