import { LoyaltyCampaign } from "components/LoyaltyCampaigns/models";
import "../components/ElectricityAgreements/models"
import { IElectricityAgreement, SimplifiedElectricityAgreement } from "../components/ElectricityAgreements/models";
import { SimplifiedChargingAgreement } from "components/ChargingAgreements/models";

export enum ModalType {
    CREATE,
    EDIT
}

export enum LanguageOption {
    ET = "ET",
    EN = "EN"
}

export interface ModalProps {
    modalType?: ModalType;
    visible?: boolean;
    handleModalHide: () => void;
    onSuccess?: () => void;
}

export interface CreateElectricityAgreementModalProps extends ModalProps {
    isOffer: boolean;
}

export interface UpdateElectricityAgreementPackageModal extends ModalProps {
    agreement: IElectricityAgreement;
    isJointInvoicePossible: boolean;
}

export interface UpdateLoyaltyCampaignModalProps extends ModalProps {
    campaign: LoyaltyCampaign
}

export interface SubmitModalProps extends ModalProps {
    onSubmitSuccess: () => void;
}

export interface SimplifiedAgreement extends SimplifiedChargingAgreement, SimplifiedElectricityAgreement {
    isElectricity: boolean;
  }  

export const BASIC_STATUS_OPTIONS = [
    { value: true, label: "Aktiivne" },
    { value: false, label: "Mitteaktiivne" }
];