import React, {PureComponent} from "react";
import { ChargingPricing } from "components/ChargingPricings/models";
import { ChargingPricingsSearchBar } from "components/ChargingPricings/ChargingPricingsSearchBar";
import { ModalType } from "common/common-models";
import { EditChargingPricingModal } from "components/ChargingPricings/modals/EditChargingPricingModal";
import { ChargingPricingsService } from "components/ChargingPricings/ChargingPricings.service";
import { ChargingPricingsTable } from "components/ChargingPricings/ChargingPricingsTable";
import { DeleteChargingPricingConfirmModal } from "components/ChargingPricings/modals/DeleteChargingPricingConfirmModal";

interface ChargingPricingsState {
  chargingPricings?: ChargingPricing[];
  isEditModalVisible: boolean;
  isDeleteModalVisible: boolean;
  modalType?: ModalType;
  chargingPricing?: ChargingPricing;
}

export default class ChargingPricings extends PureComponent<any, ChargingPricingsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isEditModalVisible: false,
            isDeleteModalVisible: false
        };
        this.props.onLoadValidatePage();
    }

    findChargingPricings = () => {
        ChargingPricingsService.findChargingPricings().then((response: ChargingPricing[]) => {
            this.setState({
                chargingPricings: response
            })
        });
    };

    openChargingPricingModal = (modalType: ModalType, chargingPricing?: ChargingPricing) => {
      this.setState({
          isEditModalVisible: true,
          modalType: modalType,
          chargingPricing: chargingPricing
      })
    };

    openDeleteChargingPricingModal = (chargingPricing: ChargingPricing) => {
        this.setState({
            isEditModalVisible: false,
            isDeleteModalVisible: true,
            chargingPricing: chargingPricing
        })
    };

    componentDidMount(): void {
        this.findChargingPricings();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                      <ChargingPricingsSearchBar
                        openChargingPricingModal={this.openChargingPricingModal}
                      />
                      {
                        this.state.chargingPricings?.length ?
                            <ChargingPricingsTable 
                                chargingPricings={this.state.chargingPricings}
                                openChargingPricingModal={this.openChargingPricingModal}
                                openDeleteChargingPricingModal={this.openDeleteChargingPricingModal}
                            /> :
                            null
                      }
                    </div>
                </div>    
                {
                    this.state.isEditModalVisible && (
                        <EditChargingPricingModal
                            handleModalHide={() => { this.setState({isEditModalVisible: false}) }}
                            modalType={this.state.modalType!}
                            loadChargingPricings={this.findChargingPricings}
                            chargingPricing={this.state.chargingPricing}
                        />
                    )
                }
                {
                    (this.state.isDeleteModalVisible && this.state.chargingPricing) && (
                        <DeleteChargingPricingConfirmModal
                            handleRejectClose={() => {
                                this.setState({isDeleteModalVisible: false})
                            }}
                            handleSubmitClose={() => {
                                this.setState({isDeleteModalVisible: false},
                                    () => {
                                        this.findChargingPricings();
                                    });
                            }}
                            chargingPricing={this.state.chargingPricing}
                        />
                    )
                }
            </div>
        );
    }
}