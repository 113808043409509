/* eslint-disable react-hooks/exhaustive-deps */
import { ICustomer } from "components/ElectrcityCustomers/model";
import { ElectricityInvoiceGroupsService } from "components/ElectricityInvoiceGroups/ElectrcityInvoiceGroups.service";
import { IInvoiceGroup } from "components/ElectricityInvoiceGroups/models";
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { InvoiceGroup } from "../../../ElectricityInvoiceGroups/components/InvoiceGroup";
import { Button, Spinner } from "react-bootstrap";
import { ElectricityCustomerSettingsModalFormState, SettingsModalNavState } from "components/ElectrcityCustomers/modals/ElectricityCustomerSettingsModal";
import { InvoiceGroupDeleteConfirmModal } from "components/ElectrcityCustomers/modals/InvoiceGroupDeleteConfirmModal";
import ModalContentBlock from "components/common/ModalContentBlock";

interface CustomerInvoiceGroupsProps {
  customer : ICustomer;
  navigate : (nextState : SettingsModalNavState) => void;
  form: ElectricityCustomerSettingsModalFormState;
  setForm: Dispatch<SetStateAction<ElectricityCustomerSettingsModalFormState>>;
}

export function CustomerInvoiceGroups({customer, navigate, form, setForm} : CustomerInvoiceGroupsProps){
  const [invoiceGroups, setInvoiceGroups] = useState<IInvoiceGroup[]>([])
  const [isLoading, setIsLoading] = useState(false);
  const [activeInvoiceGroup, setActiveInvoiceGroup] = useState<IInvoiceGroup>();

  useEffect(() => {
    findInvoiceGroups();
  }, [])

  const findInvoiceGroups = async () => {
    setIsLoading(true);

    try{
        const response = await ElectricityInvoiceGroupsService.findInvoiceGroupsForCustomer(customer.id);
        setInvoiceGroups(response);
    }catch(e){
      console.error(e)
    }finally{
      setIsLoading(false)
    }
  }

  const handleInvoiceGroupEdit = (invoiceGroup: IInvoiceGroup) => {
    setForm({
      ...form,
      invoiceGroup
    })
    navigate(SettingsModalNavState.MODIFY)
  }

  const handleInvoiceGroupRemove = async (invoiceGroup: IInvoiceGroup) => {
    setActiveInvoiceGroup(invoiceGroup);
  }

  return (
    <div className="customer-invoice-groups w-100">
      <ModalContentBlock 
        title="Arve grupid"
      >
        <div className="d-flex justify-content-end">
          <Button 
            onClick={() => navigate(SettingsModalNavState.MODIFY)}
            type="button"
          > 
            <span>Lisa uus</span>
          </Button>
        </div>
        <hr></hr>
        {isLoading ? (
          <Spinner size="sm" animation="grow"/>
        ) : (
          <>
            {invoiceGroups.map((group) => (
              <Fragment key={group.id}>
                <InvoiceGroup 
                  {...group} 
                  navigate={navigate}
                  handleEdit={handleInvoiceGroupEdit}
                  handleRemove={handleInvoiceGroupRemove}
                />
                <div className="customer-invoice-groups__divider my-4 w-100" />
              </Fragment> 
            ))}
          </>
        )}
        {(!invoiceGroups.length && !isLoading) && (
          <h2 className="text-center">Arve grupid puuduvad...</h2>
        )}
      </ModalContentBlock>
      {activeInvoiceGroup && (
        <InvoiceGroupDeleteConfirmModal 
          invoiceGroup={activeInvoiceGroup}
          handleSubmitClose={() => {
            setInvoiceGroups(
              invoiceGroups.filter(group => group.id !== activeInvoiceGroup.id)
            );
            setActiveInvoiceGroup(undefined)
          }}
          handleRejectClose={() => {
            setActiveInvoiceGroup(undefined);
          }}
        />
      )}
    </div>
  )
}