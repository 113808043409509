import { ModalType } from 'common/common-models';
import React, {PureComponent} from 'react';
import { Button } from 'react-bootstrap';
import { ChargingPricing } from './models';

interface ChargingPricingsSearchBarProps {
    openChargingPricingModal: (modalType: ModalType, chargingPricing?: ChargingPricing) => void;
}

export class ChargingPricingsSearchBar extends PureComponent<ChargingPricingsSearchBarProps, any> {

    render() {
        return (
            <div className="electricity-invoices__search-bar bar-position">
                <h2 className="text-light">
                    Laadimishinnakirjad
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <div className="d-flex flex-nowrap ml-auto">
                                <Button className="ml-auto d-flex align-items-center"
                                        onClick={() => this.props.openChargingPricingModal(ModalType.CREATE)}
                                        variant={"primary"}>
                                    <i className="icon--16 icon__add d-flex mr-1"/>
                                    <span>Lisa uus</span>
                                </Button>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}