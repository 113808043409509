import axios from 'axios';
import {apiService} from "./api.service";
import {convertToPageable, downloadFileWithPresignedUrl, extractData, handleApiException, handleResponse} from "../helpers/apiResponseHelper";
import { IAgreement, AgreementSigningStatus } from 'models/agreements';

async function getAllAgreements(pageRequest: any) {
    return axios.get(apiService.getApiUrl() + '/agreements/admin', {
        params: pageRequest
    }).then(handleResponse).then(convertToPageable);
}

async function getAgreementFileWithDownload(agreementId: number, type: 'PDF' | 'BDOC') {
    return axios.get(apiService.getApiUrl() + '/agreements/file?agreementId=' + agreementId + '&type=' + type)
        .then(handleResponse).then((res) => downloadFileWithPresignedUrl(res.data));
}

async function getAgreementSignatures(agreementId: number) {
    return axios.get(apiService.getApiUrl() + '/agreements/' + agreementId + '/signatures')
        .then(handleResponse)
        .then(extractData)
        .catch(handleApiException)
}

async function initIdCardSigning(agreementId: number, certHash: string) {
    return axios.post(apiService.getApiUrl() + '/agreements/admin/sign-init',
        {
            agreementId,
            certHash
        })
        .then(handleResponse)
        .then(extractData)
        .catch(handleApiException)
}
async function signIdCardAgreement(agreementId: number, signatureHash: string) {
    return axios.post(apiService.getApiUrl() + '/agreements/admin/sign-finalize',
        {
            agreementId,
            signatureHash
        })
        .then(handleResponse)
        .then(extractData)
        .catch(handleApiException)
}

async function initMobileIdSigning(agreementId: number, nationalIdentityCode: string, phoneNumber: string) {
    return axios.post(apiService.getApiUrl() + '/agreements/admin/sign-mobileid',
        {
            agreementId,
            nationalIdentityCode,
            phoneNumber
        })
        .then(handleResponse)
        .then(extractData)
        .catch(handleApiException)
}

async function pollMobileIdSigning(agreementId: number, nationalIdentityCode: string, verificationCode: string) {
    return axios.post(apiService.getApiUrl() + '/agreements/admin/sign-mobileid-poll',
        {
            agreementId,
            nationalIdentityCode,
            verificationCode
        })
        .then(handleResponse)
        .then(extractData)
        .catch(handleApiException)
}

async function initSmartIdSigning(agreementId: number, nationalIdentityCode: string) {
    return axios.post(apiService.getApiUrl() + '/agreements/admin/sign-smartid',
        {
            agreementId,
            nationalIdentityCode,
        })
        .then(handleResponse)
        .then(extractData)
        .catch(handleApiException)
}

async function pollSmartIdSigning(agreementId: number, nationalIdentityCode: string, verificationCode: string) {
    return axios.post(apiService.getApiUrl() + '/agreements/admin/sign-smartid-poll',
        {
            agreementId,
            nationalIdentityCode,
            verificationCode
        })
        .then(handleResponse)
        .then(extractData)
        .catch(handleApiException)
}

async function changeAgreementStatus(agreementId: number, status: AgreementSigningStatus) {
    return axios.patch(apiService.getApiUrl() + '/agreements/admin/' + agreementId + '/status',
        {
            status,
        })
        .then(handleResponse)
        .then(extractData)
        .catch(handleApiException)
}

async function createNewAgreementVersion(agreementId: number) {
    return axios.post(apiService.getApiUrl() + '/agreements/admin/' + agreementId + '/new-version', {})
        .then(handleResponse)
        .then(extractData)
        .catch(handleApiException)
}


async function deleteAgreement(agreementId: number) {
    return axios.delete(apiService.getApiUrl() + '/agreements/admin/' + agreementId)
        .catch(handleApiException)
}

function getSigningStatus(agreement: IAgreement): AgreementSigningStatus {
    if(agreement.isExpired){
        return AgreementSigningStatus.EXPIRED;
    }
    if (agreement.isSignedByCustomer && agreement.isSignedByVendor) {
        return AgreementSigningStatus.COMPLETED;
    }
    if (agreement.isSignedByCustomer && !agreement.isSignedByVendor) {
        return AgreementSigningStatus.PENDING_VENDOR;
    }
    if (!agreement.isSignedByCustomer && agreement.isSignedByVendor) {
        return AgreementSigningStatus.PENDING_CUSTOMER;
    }
    return AgreementSigningStatus.PREPARED;
}


const AgreementsService = {
    getAllAgreements,
    getAgreementFileWithDownload,
    getSigningStatus,
    initIdCardSigning,
    signIdCardAgreement,
    initMobileIdSigning,
    pollMobileIdSigning,
    getAgreementSignatures,
    changeAgreementStatus,
    deleteAgreement,
    createNewAgreementVersion,
    initSmartIdSigning,
    pollSmartIdSigning
};

export default AgreementsService;
