import { IElectricityPackage } from 'components/ElectricityPackages/models';
import { SortDirection } from './../../common/interfaces/IPageResponse';
import {IPageResponse} from "../../common/interfaces/IPageResponse";
import {ModalType} from "../../common/common-models";
import {IPageRequest} from "../../common/interfaces/IPageRequest";
import { SimplifiedChargingAgreement } from 'components/ChargingAgreements/models';

export interface IElectricityAgreementsState {
    electricityAgreements: IPageResponse<IElectricityAgreement> | null;
    pageRequest: IPageRequest<IElectricityAgreementSortFields>;
    isCreateAgreementModalVisible: boolean;
    isCreateInvoicesModalVisible: boolean;
    isUpdatePackageModalVisible: boolean;
    isInvoiceModalVisible: boolean;
    isOfferModal: boolean;
    activeAgreement: null | IElectricityAgreement; 
    isRequesting: boolean;
    isExporting: boolean;
    isGenerating: boolean;
    isDownloading: boolean;
    filters: ElectricityAgreementFilters;
    packages: string[];
    pdfPreview?: PdfPreview;
    pdfPreviewLoading?: number;
}

export interface PdfPreview {
    agreementId: number;
    objectUrl: string;
    fileName: string;
}

export interface IElectricityAgreement {
    id: number;
    contractNumber: string;
    customer: ElectricityAgreementCustomer;
    contactPerson: ElectricityAgreementContactPerson;
    dateCreated: string;
    startDate: string;
    endDate: string | null;
    consumptionPackage: IElectricityPackage;
    productionPackage: IElectricityPackage;
    consumptionMarginal: number;
    productionMarginal: number;
    status: ElectricityAgreementStatus;
    fileName: string;
    dateModified: string | null;
    meteringPoints: MeteringPoint[];
    hasLastMonthsInvoice?: boolean;
    invoiceRecipientEmail?: string;
    paidFromCustomersAdvance: boolean;
}

export interface SimplifiedElectricityAgreement {
    id: number;
    name: string;
    startDate: string;
}

export enum ElectricityAgreementStatus {
    ACTIVE = 'ACTIVE',
    ENDED = 'ENDED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    CANCELED = 'CANCELED'
}

export interface ElectricityAgreementCustomer {
    id: number;
    name: string;
    registryNumber: string;
    address: string;
    eic: string;
    isCompany: boolean;
    hasGroupInvoicing: boolean;
    bankAccountNumber?: string;
    eInvoice: boolean;
}

interface ElectricityAgreementContactPerson {
    id: number;
    name: string;
    email: string;
    phone: string;
}

export interface MeteringPoint {
    id: number;
    eic: string;
    address: string;
    newAddress?: string;
    productionCapacity?: number;
    checked?: boolean;
    relatedAgreement?: SimplifiedElectricityAgreement;
    relatedChargingAgreement?: SimplifiedChargingAgreement;
    earliestPossibleAgreementStartDate: string;
    hasMatchingGridOperator: boolean;
    terminationFee: boolean;
}

export interface SimplifiedMeteringPoint {
    id: number;
    eic: string;
    address: string;
    productionCapacity?: number;
    externalChargingStationId?: string;
}

export interface Invoice {
    id: number;
    invoiceNumber: number;
    dateIssued: string;
}

export interface IElectricAgreementTableProps {
    electricityAgreements: IPageResponse<IElectricityAgreement>;
    getPdfPreview: (agreement:IElectricityAgreement) => void;
    pdfPreviewLoading?: number
    onPageChange: (page: number, size: number) => void;
    onSort: (sortField: IElectricityAgreementSortFields) => void;
    sortField: IElectricityAgreementSortFields;
    sortDirection: SortDirection;
    updateElectricityAgreementPackage: (agreement: IElectricityAgreement) => void;
    showInvoiceModalForAgreement: (agreement: IElectricityAgreement) => void;
    cancelElectricityAgreementOffer: (agreementId: number) => void;
}

export enum IElectricityAgreementSortFields {
    ID = "ID",
    DATE_MODIFIED = "DATE_MODIFIED",
    START_DATE = 'START_DATE',
    END_DATE = 'END_DATE',
    DATE_CREATED = 'DATE_CREATED',
    MARGINAL = 'MARGINAL'
}

export interface IElectricityAgreementsSearchBarProps {
    filters: ElectricityAgreementFilters;
    packages: { value: string; label: string; }[];
    getElectricityAgreementsReport: () => void;
    onFilter: (filter: ElectricityAgreementFilters) => void;
    createAgreement: () => void;
    createAgreementOffer: () => void;
    exporting: boolean;
    generating: boolean;
    isDisabled: boolean;
}

export interface ElectricityAgreementFilters {
    search?: string;
    packageName?: string;
    status?: ElectricityAgreementStatus;
}

export interface IElectricityAgreementConfirmModalProps {
    modalType: ModalType;
    handleModalHide: () => void;
    editingElectricityAgreement?: IElectricityAgreement;
    loadElectricityAgreements: () => void;
}

export const ELECTRICITY_AGREEMENT_STATUS_MAP: Record<ElectricityAgreementStatus, string> = {
    ACTIVE: 'Aktiivne',
    ENDED: 'Lõppenud',
    PENDING: 'Ootel',
    REJECTED: 'Tagasilükatud',
    CANCELED: 'Tühistatud'
};

export interface UpdateElectricityAgreementPackageModalForm {
    isCompany?: boolean;
    validated: boolean;
    email: string;
    invoiceRecipientEmail?: string;
    eInvoice: boolean;
    bankAccountNumber?: string;
    productionMarginal?: number;
    consumptionMarginal?: number;
    consumptionPackage?: {
        id?: number;
        nameEt?: string;
        nameEn?: string;
        basePrice?: number;
        basePriceTextEt?: string;
        basePriceTextEn?: string;
        contractTitle?: string;
        pdfFooterDisclaimer?: string;
        termsAndConditionsId?: number;
        monthlyFee?: number;
        marketPricePackage?: boolean;
        pdfProductDescriptionOnInvoice?: string;
        externalProductDescriptionOnInvoice?: string;
        jointInvoicePackage?: boolean;
        isIndividual?: boolean;
    };
    startDate: string;
    isJointInvoicePossible: boolean;
}

export interface ElectricityAgreementForm {
    isCompany?: boolean;
    customerId?: number;
    customerName?: string;
    registryNumber?: string;
    vatRegistryNumber?: string;
    contactName?: string;
    contactId?: number;
    phone?: string;
    email?: string;
    invoiceRecipientEmail?: string;
    address?: string;
    city?: string;
    zip?: string;
    county?: string;
    bankAccountNumber?: string;
    validated: boolean;
    sellCheck: boolean;
    consolidatedInvoice: boolean;
    eInvoice: boolean;
    productionMarginal?: number;
    consumptionMarginal?: number;
    guarantee?: number;
    contractPeriodId?: number;
    offerDeadline?: Date;
    startDate?: Date;
    endDate?: Date;
    consumptionPackage: {
        id?: number;
        nameEt?: string;
        nameEn?: string;
        basePrice?: number;
        basePriceTextEt?: string;
        basePriceTextEn?: string;
        contractTitle?: string;
        pdfFooterDisclaimer?: string;
        termsAndConditionsId?: number;
        monthlyFee?: number;
        marketPricePackage?: boolean;
        jointInvoicePackage?: boolean;
        pdfProductDescriptionOnInvoice?: string;
        externalProductDescriptionOnInvoice?: string;
    };
    productionPackageId?: number;
    firstName?: string;
    lastName?: string;
    contactPersonCode?: string;
    isJointInvoicePossible: boolean;
}

export const INDIVIDUAL_PACKAGE_VALUE = -1;
