import { CreateChargingAgreementForm } from "components/ChargingAgreements/modals/EditChargingAgreementModal";
import { chargingBillingCycleOptions, ChargingServiceOption } from "components/ChargingAgreements/models";
import { ChargingPricing } from "components/ChargingPricings/models";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { ChargingServiceOptionService } from "services/charging/charging-service-option.service";

interface NewChargingAgreementServiceFormProps {
    form: CreateChargingAgreementForm;
    setForm: (form: CreateChargingAgreementForm) => void;
    pricing?: ChargingPricing;
}

export default function NewChargingAgreementServiceForm(
    { form, setForm, pricing } : NewChargingAgreementServiceFormProps
) {

    const [serviceOptions, setServiceOptions] = useState<ChargingServiceOption[]>([]);

    useEffect(() => {
        findPurchaseOptions();
    }, []);

    const findPurchaseOptions = async () => {
        try{
            const res = await ChargingServiceOptionService.findChargingServiceOptions();
            setServiceOptions(res)
        }catch(e){
            console.error(e);
        }
    }

    const options = serviceOptions.map(opt => ({ value: opt.id, label: opt.name }));
    const currentOption = options.find(opt => opt.value === form.serviceOptionId);
    const currentSimCardPaymentOption = chargingBillingCycleOptions.find(opt => opt.value === form.simCardPaymentType);
    const currentMaintenanceOption = chargingBillingCycleOptions.find(opt => opt.value === form.chargerMaintenanceType);

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Laadimisteenus*</Form.Label>
                        <Select
                            required
                            options={options}
                            value={currentOption ?? null}
                            onChange={(opt: any) => {
                              setForm({
                                ...form,
                                serviceOptionId: opt.value
                              })
                            }}
                            placeholder='Vali teenus'
                            className={'w-100 form-control form-select' + (!form.purchaseOptionId && form.validated ? ' is-invalid' : '')}
                        />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <Form.Label>Laadimisteenuse kuutasu (€)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.chargingServiceMonthlyFeeEuros.toString() ?? ""}
                        value={form.chargingServiceMonthlyFeeEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            chargingServiceMonthlyFeeEuros: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Laadimisteenuse marginaal (s/kWh)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.chargingServiceMarginalCentsKwh.toString() ?? ""}
                        value={form.chargingServiceMarginalCentsKwh}
                        onChange={(e: any) => setForm({
                            ...form,
                            chargingServiceMarginalCentsKwh: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>Seadme paigaldustasu (€)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.installationFeeEuros.toString() ?? ""}
                        value={form.installationFeeEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            installationFeeEuros: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Check
                        id='check-has-charger-maintenance-service'
                        custom
                        type="checkbox"
                        className='checkbox-large'
                        label='Seadme hooldusteenuse olemasolu'
                        onChange={() => setForm({ ...form, hasChargerMaintenanceService: !form.hasChargerMaintenanceService })}
                        checked={form.hasChargerMaintenanceService}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <Form.Label>Seadme hooldustasu makse sagedus</Form.Label>
                        <Select
                            options={chargingBillingCycleOptions}
                            value={currentMaintenanceOption ?? null}
                            onChange={(opt: any) => {
                              setForm({
                                ...form,
                                chargerMaintenanceType: opt?.value
                              })
                            }}
                            placeholder='Vali sagedus'
                            isClearable
                            className={'w-100 form-control form-select' + (form.validated && form.hasChargerMaintenanceService && !form.chargerMaintenanceType ? ' is-invalid' : '')}
                            isDisabled={!form.hasChargerMaintenanceService}
                        />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Seadme hoolduse kuutasu (€)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.maintenanceMonthlyFeeEuros.toString() ?? ""}
                        value={form.maintenanceMonthlyFeeEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            maintenanceMonthlyFeeEuros: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                        disabled={!form.hasChargerMaintenanceService}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>SIM-kaartide arv (tk)</Form.Label>
                    <Form.Control
                        value={form.simCardCount}
                        onChange={(e: any) => setForm({
                            ...form,
                            simCardCount: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <Form.Label>SIM-kaardi makse sagedus</Form.Label>
                        <Select
                            required={false}
                            isClearable
                            options={chargingBillingCycleOptions}
                            value={currentSimCardPaymentOption ?? null}
                            onChange={(opt: any) => {
                              setForm({
                                ...form,
                                simCardPaymentType: opt?.value
                              })
                            }}
                            placeholder='Vali sagedus'
                            className={'w-100 form-control form-select' + (form.validated && form.simCardCount > 0 && !form.simCardPaymentType ? ' is-invalid' : '')}
                        />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>SIM-kaardi kuutasu kaardi kohta (€)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.simCardMonthlyFeeEuros.toString() ?? ""}
                        value={form.simCardMonthlyFeeEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            simCardMonthlyFeeEuros: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>RFID-kaartide arv (tk)</Form.Label>
                    <Form.Control
                        value={form.rfidCardCount}
                        onChange={(e: any) => setForm({
                            ...form,
                            rfidCardCount: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>RFID-kaardi hind (€)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.rfidPaymentCardFeeEuros.toString() ?? ""}
                        value={form.rfidPaymentCardFeeEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            rfidPaymentCardFeeEuros: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>Arveldusteenuse ja arveldamise vahendamisteenuse kuutasu otsiku kohta (€)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.mediationAndBillingMonthlyFeePerNozzleEuros.toString() ?? ""}
                        value={form.mediationAndBillingMonthlyFeePerNozzleEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            mediationAndBillingMonthlyFeePerNozzleEuros: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>Infrastruktuuri kasutamise ühekordne tasu otsiku kohta (€)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.infrastructureUsageOneTimeFeePerNozzleEuros.toString() ?? ""}
                        value={form.infrastructureUsageOneTimeFeePerNozzleEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            infrastructureUsageOneTimeFeePerNozzleEuros: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Infrastruktuuri kasutamise kuutasu laadija otsiku kohta (€)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.infrastructureUsageMonthlyFeePerNozzleEuros.toString() ?? ""}
                        value={form.infrastructureUsageMonthlyFeePerNozzleEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            infrastructureUsageMonthlyFeePerNozzleEuros: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>Päevatariif (s/kWh)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.gridDayTariffCentsKwh.toString() ?? ""}
                        value={form.gridDayTariffCentsKwh}
                        onChange={(e: any) => setForm({
                            ...form,
                            gridDayTariffCentsKwh: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Öötariif (s/kWh)</Form.Label>
                    <Form.Control
                        placeholder={pricing?.gridNightTariffCentsKwh.toString() ?? ""}
                        value={form.gridNightTariffCentsKwh}
                        onChange={(e: any) => setForm({
                            ...form,
                            gridNightTariffCentsKwh: e.target.value
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
        </>
    );
}