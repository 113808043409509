import React, {PureComponent} from "react";
import { ModalType } from "common/common-models";
import { ChargingAgreementsSearchBar } from "components/ChargingAgreements/ChargingAgreementsSearchBar";
import { ChargingAgreementsTable } from "components/ChargingAgreements/ChargingAgreementsTable";
import { IPageResponse, SortDirection } from "common/interfaces/IPageResponse";
import { ChargingAgreementFilters, ChargingAgreement, ChargingAgreementSortFields } from "components/ChargingAgreements/models";
import { ChargingAgreementsService } from "components/ChargingAgreements/ChargingAgreements.service";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { IPageRequest } from "common/interfaces/IPageRequest";
import { EditChargingAgreementModal } from "components/ChargingAgreements/modals/EditChargingAgreementModal";

interface ChargingAgreementsState {
  chargingAgreements?: IPageResponse<ChargingAgreement> | undefined;
  chargingAgreement?: ChargingAgreement;
  pageRequest: IPageRequest<ChargingAgreementSortFields>;
  filters: ChargingAgreementFilters;
  isEditModalVisible: boolean;
  modalType?: ModalType;
  isRequesting: boolean;
}

const searchApi = AwesomeDebouncePromise(
    request => ChargingAgreementsService.findChargingAgreements(request),
    300
);

export default class ChargingAgreements extends PureComponent<any, ChargingAgreementsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isEditModalVisible: false,
            isRequesting: false,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.ASC,
                sortField: ChargingAgreementSortFields.ID,
                searchQuery: null
            },
            filters: { },
        };
        this.props.onLoadValidatePage();
    }

    findChargingAgreements = () => {
        ChargingAgreementsService.findChargingAgreements(this.state.pageRequest).then((response: IPageResponse<ChargingAgreement>) => {
            this.setState({
                chargingAgreements: response
            });
        });
    };

    searchChargingAgreements = (request: any) => {
        this.setState({
            isRequesting: true
        });
        searchApi(request).then((response: IPageResponse<ChargingAgreement>) => {
            this.setState({
                chargingAgreements: response, isRequesting: false
            });
        })
    };

    onPageChange = (targetPage: number) => {
        this.setState({
            pageRequest: {
                ...this.state.pageRequest,
                page: targetPage - 1
            }
        }, () => this.findChargingAgreements());
    };

    handleSortClick = (sortField: ChargingAgreementSortFields) => {
        let sortDirection = SortDirection.ASC;
        if (this.state.pageRequest.sortField.match(sortField)) {
            sortDirection = this.state.pageRequest.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
        }
        this.setState({
            pageRequest: {
                ...this.state.pageRequest,
                sortDirection,
                sortField
            }
        }, () => this.onPageChange(1))
    };

    handleFilterChange = (change: ChargingAgreementFilters) => {
        this.setState({
            chargingAgreements: undefined,
            pageRequest: {
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField
            },
            filters: {
                ...this.state.filters,
                ...change
            }
        }, () => {
            this.searchChargingAgreements({
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField,
                searchQuery: this.state.filters.search,
                status: this.state.filters.status
            });
        });
    };

    openChargingAgreementModal = (modalType: ModalType, chargingAgreement?: ChargingAgreement) => {
      this.setState({
          isEditModalVisible: true,
          modalType: modalType,
          chargingAgreement
      })
    };

    componentDidMount(): void {
        this.findChargingAgreements()
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                      <ChargingAgreementsSearchBar
                        openChargingAgreementModal={this.openChargingAgreementModal}
                        filters={this.state.filters}
                        onFilter={this.handleFilterChange}
                      />
                      {this.state.chargingAgreements !== undefined ?
                            <ChargingAgreementsTable
                                onPageChange={this.onPageChange}
                                onSort={this.handleSortClick}
                                sortDirection={this.state.pageRequest.sortDirection}
                                sortField={this.state.pageRequest.sortField}
                                chargingAgreements={this.state.chargingAgreements}
                                openChargingAgreementModal={this.openChargingAgreementModal}
                            /> :
                            null
                        }
                    </div>
                </div>    
                {
                    (this.state.isEditModalVisible && this.state.modalType === ModalType.CREATE) && (
                        <EditChargingAgreementModal
                            modalType={this.state.modalType}
                            handleModalHide={() => { this.setState({
                                isEditModalVisible: false,
                                modalType: undefined
                            }) }}
                            onSuccess={() => this.findChargingAgreements()}
                        />
                    )
                }
                {
                    (this.state.isEditModalVisible && this.state.modalType === ModalType.EDIT) && (
                        <EditChargingAgreementModal
                            modalType={this.state.modalType}
                            handleModalHide={() => { this.setState({
                                isEditModalVisible: false,
                                modalType: undefined
                            }) }}
                            onSuccess={() => this.findChargingAgreements()}
                            chargingAgreement={this.state.chargingAgreement}
                        />
                    )
                }
            </div>
        );
    }
}