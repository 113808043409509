import axios from "axios";
import { CreateChargingAgreementForm } from "./modals/EditChargingAgreementModal";
import { apiService } from "services/api.service";
import { convertToPageable, extractData, handleApiException, handleResponse } from "helpers/apiResponseHelper";
import { isoDateFormat } from "common/dateFormatterConfig";
import moment from "moment";

const TEMPORARY_CONTRACT_PERIOD_ID = 8;

async function findChargingAgreements(pageRequest: any) {
  return axios.get(apiService.getApiUrl() + '/charging/agreements/admin', {
      params: pageRequest
  }).then(handleResponse).then(convertToPageable).catch(handleApiException);
}

async function createChargingAgreement(form: CreateChargingAgreementForm) {
  return axios.post(`${apiService.getApiUrl()}/charging/agreements/admin`, {
    ...form,
    chargingStations : form.chargingStations.map(chargingStation => ({
      ...chargingStation,
      installationDate: chargingStation.installationDate ? moment(chargingStation.installationDate).startOf('day').add(3, 'hours').format(isoDateFormat) : undefined
    })),
    customer: {
      ...form.customer,
      companyName: form.customer?.customerName,
      personalIdentificationCode: form.customer?.contactPersonCode,
    },
    startDate: moment(form.startDate).startOf('day').add(3, 'hours').format(isoDateFormat),
    endDate: form.contractPeriodId !== TEMPORARY_CONTRACT_PERIOD_ID ? undefined : moment(form.endDate).startOf('day').add(3, 'hours').format(isoDateFormat),

    simCardCount: form.simCardCount ?? 0,
    rfidCardCount: form.rfidCardCount ?? 0,
    chargerMaintenanceType: form.hasChargerMaintenanceService ? form.chargerMaintenanceType : undefined,
  }).then(handleResponse).then(extractData).catch(handleApiException);
}

async function updateChargingAgreement(form: CreateChargingAgreementForm, id: number) {
  return axios.put(`${apiService.getApiUrl()}/charging/agreements/admin/${id}`, {
    ...form,
    chargingStations : form.chargingStations.map(chargingStation => ({
      ...chargingStation,
      installationDate: chargingStation.installationDate ? moment(chargingStation.installationDate).startOf('day').add(3, 'hours').format(isoDateFormat) : undefined
    })),
    startDate: moment(form.startDate).startOf('day').add(3, 'hours').format(isoDateFormat),
    endDate: form.contractPeriodId !== 8 ? undefined : moment(form.endDate).startOf('day').add(3, 'hours').format(isoDateFormat),
    simCardCount: form.simCardCount ?? 0,
    rfidCardCount: form.rfidCardCount ?? 0,
    chargerMaintenanceType: form.hasChargerMaintenanceService ? form.chargerMaintenanceType : undefined,
  }).then(handleResponse).then(extractData).catch(handleApiException);
}


export const ChargingAgreementsService = {
  findChargingAgreements,
  createChargingAgreement,
  updateChargingAgreement
};