import { ContactRole } from "models/permissions";

export interface ICustomerContactPerson {
    id: number;
    searchCode: string;
    customerId: number;
    userId: number;
    remoteCustomerId: number;
    personalIdentificationCode: string;
    contactName: string;
    customerName: string;
    webAccess: boolean;
    webAdmin: boolean;
    userLastLogin: string;
    customerRoles: ContactRole[];
    registryNumber?: string;
    vatRegistryNumber?: string;
    address?: string;
    city?: string;
    zip?: string;
    county?: string;
    bankAccountNumber?: string;
    eInvoice?: boolean;
    phone?: string;
    mobileNumber?: string;
    contactEmail?: string;
    isPerson: boolean;
    modifiedByName?: string;
    dateModified?: string;
    customerModifiedByName?: string;
    customerDateModified?: string;
}

export enum ICustomerContactPersonSortFields {
    ID = "ID",
    SEARCH_CODE = "SEARCH_CODE",
    CUSTOMER_ID = "CUSTOMER_ID",
    REMOTE_CUSTOMER_ID = "REMOTE_CUSTOMER_ID",
    PERSONAL_IDENTIFICATION_CODE = "PERSONAL_IDENTIFICATION_CODE",
    CONTACT_NAME = "CONTACT_NAME",
    WEB_ACCESS = "WEB_ACCESS",
    WEB_ADMIN = "WEB_ADMIN",
    CUSTOMER_NAME = "CUSTOMER_NAME",
    USER_LAST_LOGIN = "USER_LAST_LOGIN"
}
