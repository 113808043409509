/* eslint-disable react-hooks/exhaustive-deps */
import AddressAutocomplete from "components/AddressAutocomplete/AddressAutocomplete";
import { CreateChargingAgreementForm, NETO_COMPENSATION_ID } from "components/ChargingAgreements/modals/EditChargingAgreementModal";
import { ChargingPurchaseOption, ChargingStationModel, ChargingThirdPartyCompensationOption } from "components/ChargingAgreements/models";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { ChargingPurchaseOptionService } from "services/charging/charging-purchase-option.service";
import { ChargingStationModelService } from "services/charging/charging-station-model.service";
import { ChargingThirdPartyCompensationOptionService } from "services/charging/charging-third-party-compensation-option.service";
import { ChargingPricing } from "components/ChargingPricings/models";

interface NewChargingAgreementDeviceFormProps {
    form: CreateChargingAgreementForm;
    setForm: (form: CreateChargingAgreementForm) => void;
    pricing?: ChargingPricing;
}

export default function NewChargingAgreementDeviceForm(
    { form, setForm, pricing } : NewChargingAgreementDeviceFormProps
) {
    const [purchaseOptions, setPurchaseOptions] = useState<ChargingPurchaseOption[]>([]);
    const [compensationOptions, setCompensationOptions] = useState<ChargingThirdPartyCompensationOption[]>([]);
    const [stationModels, setStationModels] = useState<ChargingStationModel[]>([]);

    useEffect(() => {
        findPurchaseOptions();
        findCompensationOptions();
        findStationModels();
    }, []);

    const findStationModels = async () => {
        try{
          const res = await ChargingStationModelService.findChargingStationModels();
          setStationModels(
            res
          )
        }catch(e){
          console.error(e);
        }
      }

    const findPurchaseOptions = async () => {
        try{
            const res = await ChargingPurchaseOptionService.findChargingPurchaseOptions();
            setPurchaseOptions(res)
        }catch(e){
            console.error(e);
        }
    }

    const findCompensationOptions = async () => {
        try{
            const res = await ChargingThirdPartyCompensationOptionService.findChargingThirdPartyCompensationOptions();
            setCompensationOptions(res)
        }catch(e){
            console.error(e);
        }
    }

    const handleChange = (index: number, event: any) => {
        setForm({
          ...form,
          chargingStations: form.chargingStations.map((station, idx) => idx !== index ? station : {...station, [event.target.name]: event.target.value })
        })
      };

    const purchaseSelectOptions = purchaseOptions.map(opt => ({ value: opt.id, label: opt.name }));
    const purchaseSelectCurrentOption = purchaseSelectOptions.find(opt => opt.value === form.purchaseOptionId);

    const compensationSelectOptions = compensationOptions.map(opt => ({ value: opt.id, label: opt.name }));
    const compensationSelectCurrentOption = compensationSelectOptions.find(opt => opt.value === form.thirdPartyCompensationOptionId);

    const stationModelOptions = stationModels.map(model => ({ value: model.id, label: model.name }));

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Seadmete soetamine*</Form.Label>
                        <Select
                            required
                            options={purchaseSelectOptions}
                            value={purchaseSelectCurrentOption ?? null}
                            onChange={(opt: any) => {
                              setForm({
                                ...form,
                                purchaseOptionId: opt.value
                              })
                            }}
                            placeholder='Vali lahendus'
                            className={'w-100 form-control form-select' + (!form.purchaseOptionId && form.validated ? ' is-invalid' : '')}
                        />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Seadme kasutuskoha aadress</Form.Label>
                    <AddressAutocomplete
                        required={false}
                        address={form.address}
                        setAddress={(address) => setForm({
                            ...form,
                            address: address.fullAddress || address.address,
                            city: address.city,
                            zip: address.zip,
                        })}
                        disabled={false}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12 col-md-6">
                    <Form.Label>Seadmete kasutamise kompensatsioon*</Form.Label>
                        <Select
                            required
                            options={compensationSelectOptions}
                            value={compensationSelectCurrentOption ?? null}
                            onChange={(opt: any) => {
                              setForm({
                                ...form,
                                thirdPartyCompensationOptionId: opt.value
                              })
                            }}
                            placeholder='Vali lahendus'
                            className={'w-100 form-control form-select' + (!form.thirdPartyCompensationOptionId && form.validated ? ' is-invalid' : '')}
                        />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                {
                    form.thirdPartyCompensationOptionId === NETO_COMPENSATION_ID && (
                        <div className="col-12 col-md-6">
                            <Form.Label>Kolmanda osapoole neto kompensatsioon*</Form.Label>
                            <Form.Control
                                required
                                placeholder={pricing?.thirdPartyCompensationNetoFeeEuros.toString() ?? ""}
                                value={form.thirdPartyCompensationNetoFeeEuros}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    thirdPartyCompensationNetoFeeEuros: e.target.value
                                })}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    )
                }    
            </div>
            <h2 className="my-3">Laadimisjaamad</h2>
            <div>
              {form.chargingStations?.map((station, index) => {
                const currentOption = stationModelOptions.find(opt => opt.value === station.stationModelId) || null;

                return (
                  <div key={index} className="charging-station-form mb-4">
                    <div className="row mt-3">
                      <div className="col-6">
                          <Form.Label>Mudel*</Form.Label>
                              <Select
                                  id={`station-model-select-${index}`}
                                  required
                                  options={stationModelOptions}
                                  value={currentOption}
                                  onChange={(opt: any) => {
                                    setForm({
                                      ...form,
                                      chargingStations: form.chargingStations.map(
                                        (charginStation, idx) => 
                                            idx === index
                                            ? { ...charginStation, stationModelId: opt.value }
                                            : charginStation
                                      )
                                    })
                                  }}
                                  placeholder='Vali mudel'
                                  className={'form-control form-select' + (!station.stationModelId && form.validated ? ' is-invalid' : '')}
                              />
                          <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                      </div>
                      <div className="col-6">
                        <Form.Label>Seerianumber*</Form.Label>
                          <Form.Control
                              value={station.serialNumber}
                              onChange={(e: any) => handleChange(index, e)}
                              required
                              name="serialNumber"
                          />
                          <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <Form.Label>Laiuskraad</Form.Label>
                          <Form.Control
                              value={station.latitude}
                              onChange={(e: any) => handleChange(index, e)}
                              name="latitude"
                          />
                          <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                      </div>
                      <div className="col-6">
                        <Form.Label>Pikkuskraad</Form.Label>
                          <Form.Control
                              value={station.longitude}
                              onChange={(e: any) => handleChange(index, e)}
                              name="longitude"
                          />
                          <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <Form.Label htmlFor="installation-date">Paigalduskuupäev</Form.Label>
                        <DatePicker
                            className={'d-block'}
                            value={station.installationDate}
                            locale='et'
                            clearIcon={null}
                            calendarIcon={<CalendarIcon />}
                            format='dd.MM.yyyy'
                            onChange={(event: any) => {
                              setForm({
                                ...form,
                                chargingStations: form.chargingStations.map(
                                  (charginStation, idx) => idx !== index ? charginStation : {...charginStation, installationDate: new Date(event) }
                                )
                              })
                            }}
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                      </div>
                      <div className="col-6">
                        <Form.Label>Paigalduse lisatasu (€)</Form.Label>
                          <Form.Control
                              value={station.installationExtraFeeEuros}
                              onChange={(e: any) => handleChange(index, e)}
                              type="number"
                              name="installationExtraFeeEuros"
                              onWheel={(e: any) => e.target.blur()}
                          />
                          <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <Form.Label>Otsikute arv (tk)</Form.Label>
                          <Form.Control
                              value={station.nozzleCount}
                              onChange={(e: any) => handleChange(index, e)}
                              type="number"
                              name="nozzleCount"
                              onWheel={(e: any) => e.target.blur()}
                          />
                          <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                      </div>
                    </div>
                    <button
                      type='button'
                      className='d-flex align-items-center btn btn-link pl-0 ml-auto'
                      onClick={(e) => {
                        e.preventDefault()
                        setForm({
                          ...form,
                          chargingStations: form.chargingStations.filter((_, idx) => idx !== index)
                        })
                      }}
                    >
                        <i className="icon--16 icon__remove--primary d-flex mr-1"></i>
                        <span>Eemalda</span>
                    </button>
                  </div>
                ) 
              }
              )}
            </div>
            <Button 
              variant="primary" 
              onClick={(e: any) => {
                e.preventDefault();
                setForm({
                  ...form,
                  chargingStations: [
                    ...form.chargingStations, 
                    {}
                  ]
                })
              }} 
              type="button"
              className="d-flex align-items-center mt-3 mb-2"
              >
               Lisa uus +
            </Button>
        </>
    );
}