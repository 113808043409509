import {AxiosResponse} from "axios";
import {IPageResponse} from "../common/interfaces/IPageResponse";
import { toast } from "react-toastify";

export function handleApiException({ response }: any) {
    if (response.data.message) {
        toast(response.data.message, {
            position: "top-right",
            type: 'error'
          })
    }
    console.error("Response returned error " + response.status, response.statusText);
    return Promise.reject(response.data || "Response returned error " + response.status + " >> " + response.statusText);
}

export function handleResponse(response: AxiosResponse<any>): Promise<any> | AxiosResponse<any>;
export function handleResponse(response: AxiosResponse<any>, pure: boolean): Promise<any> | AxiosResponse<any>;
export function handleResponse(response: AxiosResponse<any>, pure?: boolean): Promise<any> | AxiosResponse<any> {
    if (response.status === 200) {
        return pure ? response : response.data;
    }else if(response.status === 204){
        return response;
    }
    console.error("Response returned error " + response.status, response.statusText);
    return Promise.reject("Response returned error " + response.status + " >> "+ response.statusText);
}

export function convertToPageable(response: any): IPageResponse<any> {
    if (response !== null)
        return response.data;
    else {
        console.error("Unable to convert response to page response");
        return response;
    }
}

export function extractData(response: any): any {
    if (response !== null)
        return response.data;
    else {
        console.error("Unable to extract data from response");
        return response;
    }
}

export function extractFileNameFromResponse(res: AxiosResponse<any>, fallback: string) {
    const contentDispositionHeader = res.headers["content-disposition"];
    
    if (!contentDispositionHeader) {
        return fallback;
    }
    
    const fileName = contentDispositionHeader.split("filename=")[1];
    
    return fileName ? fileName : fallback;
}

export function downloadFile(fileName: string, blob: Blob) {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
}

export function downloadFileWithPresignedUrl(fileUrl: string) {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.click();
}

export function handleReportResponse(response: AxiosResponse<any>) {
    if (response.status === 200) {
        let fileName = (response.headers["content-disposition"].split(' ')[1]).split('=')[1];
        const data = response.data;        
        return {fileName, data};
    }
    console.error("Response returned error " + response.status, response.statusText);
    return Promise.reject("Response returned error " + response.status + " >> "+ response.statusText);
}  