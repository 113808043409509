import React, {PureComponent} from 'react';

import {IPageResponse, SortDirection} from "../common/interfaces/IPageResponse";
import { AgreementFilterType, IAgreement, IAgreementRequest, IAgreementsSortFields } from "../models/agreements";
import {AgreementsTable} from "../components/Agreements/AgreementsTable";
import AgreementsService from "services/agreements.service";
import {AgreementsSearchBar} from "../components/Agreements/AgreementsSearchBar";
import AgreementSigningModal from 'components/Agreements/modals/AgreementSigningModal';
import AgreementSignaturesModal from 'components/Agreements/modals/AgreementSignaturesModal';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { AgreementDeleteConfirmModal } from 'components/Agreements/modals/AgreementDeleteConfirmModal';
import { AgreementNewVersionConfirmModal } from 'components/Agreements/modals/AgreementNewVersionConfirmModal';
import AgreementStatusChangeModal from 'components/Agreements/modals/AgreementStatusChangeModal';

export interface IAgreementsState {
    agreements: IPageResponse<IAgreement> | null;
    pageRequest: IAgreementRequest;
    isAgreementSigningModalOpen: boolean;
    isAgreementSignaturesModalOpen: boolean;
    isAgreementStatusChangeModalOpen: boolean;
    isAgreementDeleteModalOpen: boolean;
    isAgreementNewVersionModalOpen: boolean;
    selectedAgreement: IAgreement | null;
    isRequesting: boolean;
    filters: { [key in AgreementFilterType]?: any };
}

const searchApi = AwesomeDebouncePromise(
    request => AgreementsService.getAllAgreements(request),
    200
);

class Agreements extends PureComponent<any, IAgreementsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            agreements: null,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.DESC,
                sortField: IAgreementsSortFields.DATE_MODIFIED,
                searchQuery: null
            },
            filters: {
                [AgreementFilterType.STATUS]: null,
                [AgreementFilterType.SEARCH]: null
            },
            isAgreementSigningModalOpen: false,
            isAgreementSignaturesModalOpen: false,
            isAgreementStatusChangeModalOpen: false,
            isAgreementDeleteModalOpen: false,
            isAgreementNewVersionModalOpen: false,
            selectedAgreement: null,
            isRequesting: false
        };
        this.props.onLoadValidatePage();
    }

    getAgreements = () => {
        this.setState({
            isRequesting: true
        })
        AgreementsService.getAllAgreements(this.state.pageRequest).then((response: IPageResponse<IAgreement>) => {
            this.setState({
                agreements: response,
                isRequesting: false
            });
        });
    };

    getAgreementFile = async (agreement: IAgreement, type: 'PDF' | 'BDOC') => {
        await AgreementsService.getAgreementFileWithDownload(agreement.id, type);
    }

    onPageChange = (targetPage: number) => {
        if (this.state.pageRequest.page + 1 !== targetPage) {
            this.setState({
                pageRequest: {
                    ...this.state.pageRequest,
                    page: targetPage - 1
                }
            }, () => this.getAgreements());
        }
    };
    onSortChange = (sortedByField: IAgreementsSortFields, sortDirection: SortDirection) => {
        this.setState({
            ...this.state,
            pageRequest: {
              ...this.state.pageRequest,
                sortDirection: sortDirection,
                sortField: sortedByField
            }
        }, () => {
            this.getAgreements();
        });
    };

    onFilterChange = (type: AgreementFilterType, value: any) => {
        const pageRequest = {
            ...this.state.pageRequest,
            page: 0
        };
        if (type === AgreementFilterType.STATUS) {
            pageRequest.statuses = value ? value.value : undefined;
        }
        this.setState({
            filters: {
                ...this.state.filters,
                [type]: value
            },
            pageRequest
        }, () => this.getAgreements());
    }

    openSigningModal = (agreement: IAgreement) => {
        this.setState({
            isAgreementSigningModalOpen: true,
            selectedAgreement: agreement
        })
    };

    openViewSignatures = (agreement: IAgreement) => {
        this.setState({
            isAgreementSignaturesModalOpen: true,
            selectedAgreement: agreement
        });
    };
 
    onSigningSubmitSuccess = () => {
        this.setState({
            isAgreementSigningModalOpen: false,
            selectedAgreement: null
        });        
        this.getAgreements();
    };

    changeAgreementStatus = (agreement: IAgreement) => {
        this.setState({
            isAgreementStatusChangeModalOpen: true,
            selectedAgreement: agreement
        })
    };

    deleteAgreement = (agreement: IAgreement) => {
        this.setState({
            isAgreementDeleteModalOpen: true,
            selectedAgreement: agreement
        })
    }
    onDeleteSuccess = () => {
        this.setState({
            isAgreementDeleteModalOpen: false,
            selectedAgreement: null
        });
        this.getAgreements();
    }
    createNewAgreementVersion = (agreement: IAgreement) => {
        this.setState({
            isAgreementNewVersionModalOpen: true,
            selectedAgreement: agreement
        })
    }
    onCreateNewSuccess = () => {
        this.setState({
            isAgreementNewVersionModalOpen: false,
            selectedAgreement: null
        });
        this.getAgreements();
    }

    onStatusChangeSuccess = () => {
        this.setState({
            isAgreementStatusChangeModalOpen: false,
            selectedAgreement: null
        });        
        this.getAgreements();
    };

    handleSearchChange = (change: any) => {
        this.setState({
            isRequesting: true,
            filters: {
                ...this.state.filters,
                SEARCH: change.searchQuery
            },
            pageRequest: {
                ...this.state.pageRequest,
                page: 0,
                searchQuery: change.searchQuery
            },
        });
        this.searchAgreements({
                page: this.state.pageRequest.page,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField,
                searchQuery: change.searchQuery
        });
    };

    searchAgreements = (request: any) => {
        searchApi(request).then((response: IPageResponse<IAgreement>) => {
                        this.setState({
                            agreements: response,
                            isRequesting: false
                        });
                    })
        
    };

    componentDidMount(): void {
        this.getAgreements();
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <AgreementsSearchBar
                            onFilterChange={this.onFilterChange}
                            onSearch={this.handleSearchChange}         
                            filters={this.state.filters}
                        />
                        {this.state.agreements !== null ?
                            <AgreementsTable
                                getAgreementFile={this.getAgreementFile}
                                openSigningModal={this.openSigningModal}
                                openSignatures={this.openViewSignatures}
                                changeAgreementStatus={this.changeAgreementStatus}
                                deleteAgreement={this.deleteAgreement}
                                createNewVersion={this.createNewAgreementVersion}
                                agreements={this.state.agreements}
                                onPageChange={this.onPageChange}
                                onSortChange={this.onSortChange}
                                isRequesting={this.state.isRequesting}
                            /> :
                            null
                        }
                        {this.state.isAgreementSigningModalOpen &&
                            <AgreementSigningModal
                                handleModalHide={() => this.setState({ isAgreementSigningModalOpen: false, selectedAgreement: null })}
                                onSubmitSuccess={() => this.onSigningSubmitSuccess()}
                                agreement={this.state.selectedAgreement} />
                        }
                        {this.state.isAgreementSignaturesModalOpen &&
                            <AgreementSignaturesModal
                                handleModalHide={() => this.setState({ isAgreementSignaturesModalOpen: false, selectedAgreement: null })}
                                agreement={this.state.selectedAgreement}
                            />
                        }
                        {this.state.isAgreementStatusChangeModalOpen &&
                            <AgreementStatusChangeModal
                                handleModalHide={() => this.setState({ isAgreementStatusChangeModalOpen: false, selectedAgreement: null })}
                                agreement={this.state.selectedAgreement}
                                onSubmitSuccess={() => this.onStatusChangeSuccess()}
                            />
                        }
                        {
                            this.state.isAgreementDeleteModalOpen && this.state.selectedAgreement &&
                            <AgreementDeleteConfirmModal
                                handleRejectClose={() => {
                                    this.setState({isAgreementDeleteModalOpen: false, selectedAgreement: null})
                                }}
                                handleSubmitClose={this.onDeleteSuccess}
                                agreement={this.state.selectedAgreement}
                            />
                        }
                        {
                            this.state.isAgreementNewVersionModalOpen && this.state.selectedAgreement &&
                            <AgreementNewVersionConfirmModal
                                handleRejectClose={() => {
                                    this.setState({isAgreementNewVersionModalOpen: false, selectedAgreement: null})
                                }}
                                handleSubmitClose={this.onCreateNewSuccess}
                                agreement={this.state.selectedAgreement}
                            />
                        }                                                    
                    </div>
                </div>
            </div>
        );
    }
}

export default Agreements;