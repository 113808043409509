/* eslint-disable jsx-a11y/alt-text */
import React from "react";

interface IconProps {
  src: string;
  file?: never;
}

interface FileProps {
  file: File;
  src?: never;
}

type FilePreviewProps = (IconProps | FileProps) & {
  className?: string
}

export function FilePreview({ src, className} : FilePreviewProps){
 return (
    <div className={`file-preview ${className}`}>
        <img src={src} />
    </div>
  )
}