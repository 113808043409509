/* eslint-disable react-hooks/exhaustive-deps */
import { ModalProps } from "common/common-models";
import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { ICustomer } from "../model";
import { CustomerDetails } from "../components/ElectricityCustomerSettingsModal/CustomerDetails";
import { CustomerInvoiceGroups } from "../components/ElectricityCustomerSettingsModal/CustomerInvoiceGroups";
import { InvoiceGroupForm } from "../components/ElectricityCustomerSettingsModal/InvoiceGroupForm";
import { IElectricityAgreement, SimplifiedMeteringPoint } from "components/ElectricityAgreements/models";
import { ElectricityInvoiceGroupsService } from "components/ElectricityInvoiceGroups/ElectrcityInvoiceGroups.service";
import CustomerService from "services/customers.service";
import { CustomerAgreement } from "../components/ElectricityCustomerSettingsModal/CustomerAgreement";
import { ElectricityAgreementsService } from "components/ElectricityAgreements/ElectricityAgreements.service";
import { toast } from "react-toastify";
import { BANK_ACCOUNT_NUMBER_REGEX_EXPRESSION, EMAIL_REGEX_EXPRESSION } from "common/constants/regex";
import { normalizeCustomersInvoiceRecipientEmail } from "helpers";

interface ElectricityCustomerSettingsModalProps extends ModalProps {
  customer: ICustomer;
}

export interface ElectricityCustomerSettingsModalFormState{
  isGroupInvoicingEnabled: boolean;
  receiveInvoiceByEmail: boolean;
  isFactoringClient: boolean;
  invoiceRecipientEmail?: string;
  bankAccountNumber?: string;
  validated: boolean;
  invoiceGroup?: {
    id?: number;
    name?: string;
    referenceNumber?: string;
    meteringPoints?: SimplifiedMeteringPoint[]
    invoiceRecipientEmail?: string;
    paidFromCustomersAdvance: boolean;
  }
  agreement?: {
    invoiceRecipientEmail?: string;
    paidFromCustomersAdvance: boolean;
  };
}

export enum SettingsModalNavState {
  DETAILS,
  INVOICE_GROUPS,
  AGREEMENT,
  METERING_POINT,
  MODIFY
}

export function ElectricityCustomerSettingsModal(props: ElectricityCustomerSettingsModalProps) {
    const [form, setForm] = useState<ElectricityCustomerSettingsModalFormState>({
      isGroupInvoicingEnabled: props.customer.isGroupInvoicingEnabled,
      isFactoringClient: props.customer.isFactoringClient,
      bankAccountNumber: props.customer.bankAccountNumber,
      invoiceRecipientEmail: props.customer.invoiceRecipientEmail,
      receiveInvoiceByEmail: props.customer.receiveInvoiceByEmail,
      validated: false
    });

    const [currentState, setCurrentState] = useState(SettingsModalNavState.DETAILS);
    const [isSaving, setIsSaving] = useState(false);
    const [activeAgreement, setActiveAgreement] = useState<IElectricityAgreement>() 

    const navigate = (nextState : SettingsModalNavState) => {
        setCurrentState(nextState)
    }

    const validateGroupName = () => {
      if(form.invoiceGroup?.id){
        return !!form.invoiceGroup.name?.length
      }
      return true;
    }

    const validateMeteringPoints = () => {
      return !!form.invoiceGroup?.meteringPoints?.length;
    }

    const validateEmail = (email?: string) => {
      if(!email){
        return true;
      }

      return email.split(",").every(email => EMAIL_REGEX_EXPRESSION.test(email.trim()));
    }

    const validateBankAccountNumber = () => {
      if (form.bankAccountNumber) {
          return BANK_ACCOUNT_NUMBER_REGEX_EXPRESSION.test(form.bankAccountNumber!);
      }
      
      return true;
  }

    const validate = (): boolean => {
      setForm({ ...form, validated: true });

      if(currentState === SettingsModalNavState.MODIFY){
        return validateEmail(form.invoiceGroup?.invoiceRecipientEmail) && validateGroupName() && validateMeteringPoints();
      }

      if(currentState === SettingsModalNavState.DETAILS){
        return validateEmail(form.invoiceRecipientEmail) && validateBankAccountNumber();
      }

      if(currentState === SettingsModalNavState.AGREEMENT){
        return validateEmail(form.agreement?.invoiceRecipientEmail);
      }

      return true;
    }

    const createInvoiceGroupForCustomer = async () => {
      setIsSaving(true);

      try{
          await ElectricityInvoiceGroupsService.createInvoiceGroupForCustomer({
            customerId: props.customer.id,
            name: form.invoiceGroup?.name,
            invoiceRecipientEmail: form.invoiceGroup?.invoiceRecipientEmail,
            referenceNumber: form.invoiceGroup?.referenceNumber,
            meteringPointIds: form.invoiceGroup!.meteringPoints!.map(mp => mp.id),
            paidFromCustomersAdvance: form.invoiceGroup!.paidFromCustomersAdvance ?? false
          });
          setForm({
            ...form, 
            validated: false,
            invoiceGroup: undefined
          })
          navigate(SettingsModalNavState.INVOICE_GROUPS);
          toast("Arve grupi loomine õnnestus", {
            position: "top-right",
            type: 'success'
          })
      }catch(e){
        console.error(e)
        toast("Arve grupi loomine ebaõnnestus", {
          position: "top-right",
          type: 'error'
        })
      }finally{
        setIsSaving(false);
      }
    }

    const updateInvoiceGroup = async () => {
      setIsSaving(true);

      try{
          await ElectricityInvoiceGroupsService.updateInvoiceGroup(form.invoiceGroup?.id!, {
            name: form.invoiceGroup?.name,
            referenceNumber: form.invoiceGroup?.referenceNumber,
            invoiceRecipientEmail: form.invoiceGroup?.invoiceRecipientEmail,
            meteringPointIds: form.invoiceGroup!.meteringPoints!.map(mp => mp.id),
            paidFromCustomersAdvance: form.invoiceGroup!.paidFromCustomersAdvance
          });
          setForm({
            ...form, 
            validated: false,
            invoiceGroup: undefined
          })
          navigate(SettingsModalNavState.INVOICE_GROUPS)
          toast("Arve grupi uuendamine õnnestus", {
            position: "top-right",
            type: 'success'
          })
      }catch(e){
        console.error(e)
        toast("Arve grupi uuendamine ebaõnnestus", {
          position: "top-right",
          type: 'error'
        })
      }finally{
        setIsSaving(false);
      }
    }

    const updateCustomer = async () => {
      setIsSaving(true);

      try{
          await CustomerService.updateCustomer({
            hasGroupInvoicingEnabled: form.isGroupInvoicingEnabled,
            isFactoringClient: form.isFactoringClient,
            invoiceRecipientEmail : normalizeCustomersInvoiceRecipientEmail(form.invoiceRecipientEmail),
            bankAccountNumber: form.bankAccountNumber,
            receiveInvoiceByEmail: form.receiveInvoiceByEmail
          }, props.customer.id);
          props.onSuccess?.();
      }catch(e){
        console.error(e)
        toast("Kliendi uuendamine ebaõnnestus", {
          position: "top-right",
          type: 'error'
        })
      }finally{
        setIsSaving(false);
      }
    }

    const updateAgreement = async () => {
      setIsSaving(true);

      try{
          await ElectricityAgreementsService.updateElectricityAgreement(
            activeAgreement!.id,
            {
              invoiceRecipientEmail: form.agreement!.invoiceRecipientEmail || undefined,
              paidFromCustomersAdvance: form.agreement!.paidFromCustomersAdvance
            }
          )
          toast("Lepingu uuendamine õnnestus", {
            position: "top-right",
            type: 'success'
          })
      }catch(e){
        console.error(e)
        toast("Lepingu uuendamine ebaõnnestus", {
          position: "top-right",
          type: 'error'
        })
      }finally{
        setIsSaving(false);
      }
    }

    const submit = async () => {    
      if(!validate()){
        return;
      }
    
      if(currentState === SettingsModalNavState.DETAILS){
        await updateCustomer();
        toast("Kliendi uuendamine õnnestus", {
          position: "top-right",
          type: 'success'
        })
      }else if(currentState === SettingsModalNavState.MODIFY){
        if(
          props.customer.isGroupInvoicingEnabled === false &&
          form.isGroupInvoicingEnabled === true
        ){
          await updateCustomer();
        }

        if(!form.invoiceGroup?.id){
          await createInvoiceGroupForCustomer();
        }else{
          await updateInvoiceGroup();
        }
      }else if(currentState === SettingsModalNavState.AGREEMENT){
        await updateAgreement();
      }
    }

    return (
      <Modal 
        size="xl" 
        show={true} 
        onHide={props.handleModalHide}
        backdrop="static"
        dialogClassName="bg-light"
      >
        <Modal.Header>
            <Modal.Title>
              Seaded
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {
            currentState === SettingsModalNavState.DETAILS && (
              <CustomerDetails 
                customer={props.customer} 
                form={form}
                setForm={setForm}
                navigate={navigate}
                setActiveAgreement={setActiveAgreement}
                invoiceRecipientIsInvalid={form.validated && !validateEmail(form.invoiceRecipientEmail)}
              />
            )
           }
           { 
            currentState === SettingsModalNavState.INVOICE_GROUPS && (
              <CustomerInvoiceGroups
                customer={props.customer} 
                navigate={navigate}
                form={form}
                setForm={setForm}
              />
            )
           }
          {  
            currentState === SettingsModalNavState.MODIFY && (
              <InvoiceGroupForm
                navigate={navigate}
                customerId={props.customer.id}
                form={form}
                setForm={setForm}
                invoiceRecipientIsInvalid={form.validated && !validateEmail(form.invoiceGroup?.invoiceRecipientEmail)}
              />
            )
           }
           {  
            (currentState === SettingsModalNavState.AGREEMENT && activeAgreement) && (
              <CustomerAgreement
                form={form}
                setForm={setForm}
                agreement={activeAgreement}
                invoiceRecipientIsInvalid={form.validated && !validateEmail(form.agreement?.invoiceRecipientEmail)}
              />
            )
           }
        </Modal.Body>
        <Modal.Footer>
            {
              (
                currentState === SettingsModalNavState.INVOICE_GROUPS
              ) &&  (
                <Button
                    variant="secondary"
                    type="button"
                    onClick={() => navigate(SettingsModalNavState.DETAILS)}
                    style={{
                      width: "100px"
                    }}
                  >
                      <div>
                        <span>Tagasi</span>
                      </div>
                  </Button>
              )
            }
            {
              (
                currentState === SettingsModalNavState.MODIFY ||
                currentState === SettingsModalNavState.AGREEMENT ||
                currentState === SettingsModalNavState.METERING_POINT
              ) ? (
                <Button
                    variant="secondary" 
                    onClick={() => {
                      if(currentState === SettingsModalNavState.MODIFY){
                        navigate(SettingsModalNavState.INVOICE_GROUPS)
                        setForm({
                          ...form, 
                          validated: false,
                          invoiceGroup: undefined
                        })
                      }else{
                        navigate(SettingsModalNavState.DETAILS)
                        setForm({
                          ...form, 
                          validated: false,
                          agreement: undefined
                        })
                        setActiveAgreement(undefined)
                      }
                    }}
                    type="button"
                >
                    <span className='px-3'>Tühista</span>
                </Button>
              ) : (
                <Button
                    variant="secondary" 
                    onClick={props.handleModalHide}
                    type="button"
                >
                    <span className='px-3'>Sulge</span>
                </Button>
              )
            }
            {
              (
                currentState === SettingsModalNavState.DETAILS ||
                currentState === SettingsModalNavState.MODIFY ||
                currentState === SettingsModalNavState.AGREEMENT ||
                currentState === SettingsModalNavState.METERING_POINT
              ) && (
                <Button
                    variant="primary"
                    type="button"
                    onClick={() => submit()}
                    style={{
                      width: "100px"
                    }}
                  >
                    {isSaving ? (
                        <Spinner size={"sm"} animation={"border"}/>
                    ) : (
                      <span>Salvesta</span>
                    )}
                  </Button>
              )
            }
        </Modal.Footer>
    </Modal>      
    );
}