import React from "react";
import {ChargingPricing} from "./models";
import {dateFormatOptions} from "../../common/dateFormatterConfig";
import { ModalType } from "common/common-models";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface ChargingPricingsTableProps {
  chargingPricings: ChargingPricing[];
  openChargingPricingModal: (modalType: ModalType, chargingPricing?: ChargingPricing) => void;
  openDeleteChargingPricingModal: (chargingPricing: ChargingPricing) => void;
}

export function ChargingPricingsTable(props: ChargingPricingsTableProps) {
    const chargingPricingTableRows = props.chargingPricings.map((chargingPricing: ChargingPricing) =>
        <tr key={'row-' + chargingPricing.id}>
            <td>
                {chargingPricing.id}
            </td>
            <td className="w-25">
                {chargingPricing.name}
            </td>
            <td>
                {chargingPricing.chargingServiceMarginalCentsKwh}
            </td>
            <td>
                {chargingPricing.chargingServiceMonthlyFeeEuros}
            </td>
            <td>
            </td>
            <td>
                {
                    chargingPricing.dateModified ? new Intl.DateTimeFormat('et', dateFormatOptions).format(new Date(chargingPricing.dateModified)) : ""
                }
            </td>
            <td>
                <div className="row__actions">
                    <OverlayTrigger 
                        delay={250}
                        overlay={
                            <Tooltip id={`tooltip-${chargingPricing.id}`}>
                                <p style={{margin: '3px'}}>Muuda</p>
                            </Tooltip>
                        } 
                        placement={"left"}
                    >
                        <Button 
                            size="sm" 
                            variant="outline-dark"
                            className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                            onClick={(e: any) => {
                                props.openChargingPricingModal(ModalType.EDIT, chargingPricing);
                            }}
                        >
                            <i className="icon--16 my-2 icon__edit--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger 
                        delay={250}
                        overlay={
                            <Tooltip id={`tooltip-${chargingPricing.id}`}>
                                <p style={{margin: '3px'}}>Kustuta</p>
                            </Tooltip>
                        } 
                        placement={"left"}
                    >
                        <Button size="sm" variant="outline-danger"
                                className="d-flex align-items-center border-color-background border-radius-circle btn-outline-icon"
                                onClick={(e: any) => {
                                    props.openDeleteChargingPricingModal(chargingPricing);
                                }}>
                            <i className="icon--16 my-2 icon__delete--outline-mask"/>
                        </Button>
                    </OverlayTrigger>
                </div>
            </td>
        </tr>
    );

    return (
        <table className='generic-table table table-responsive-md table-hover'>
            <thead>
                <tr>
                    <th>Id</th>
                    <th className="w-25">Nimi</th>
                    <th>Laadimisteenuse marginaal (s/kWh)</th>
                    <th>Laadimisteenuse kuutasu (€)</th>
                    <th></th>
                    <th>Viimati muudetud</th>
                    <th>Tegevused</th>
                </tr>
            </thead>
            <tbody>
                {chargingPricingTableRows}
            </tbody>
        </table>
    )
}