import React, {useState} from 'react';
import {Button, Modal, Spinner } from "react-bootstrap";
import { ChargingBillingCycle, ChargingAgreement } from '../models';
import NewChargingAgreementData from '../components/NewChargingAgreement/NewChargingAgreementData';
import { ChargingAgreementsService } from '../ChargingAgreements.service';
import { ChargingPricing } from 'components/ChargingPricings/models';
import { ModalProps, ModalType } from 'common/common-models';

interface IEditChargingAgreementModalProps extends ModalProps {
    chargingAgreement?: ChargingAgreement;
}

export interface CreateChargingAgreementForm {
    validated: boolean;
    customer?: ChargingAgreementCustomerForm;
    address?: string;
    city?: string;
    zip?: string;
    externalChargingStationId?: string;
    meteringPointId?: number;
    purchaseOptionId?: number;
    serviceOptionId?: number;
    thirdPartyCompensationOptionId?: number;
    chargingStations: ChargingAgreementStationForm[]
    pricingId?: number;
    termsAndConditionsId?: number;
    contractPeriodId?: number;
    startDate?: Date;
    endDate?: Date;
    hasChargerMaintenanceService: boolean;
    chargerMaintenanceType?: ChargingBillingCycle;
    simCardCount: number;
    simCardPaymentType?: ChargingBillingCycle;
    rfidCardCount: number;
    installationFeeEuros?: number;
    thirdPartyCompensationNetoFeeEuros?: number;
    maintenanceMonthlyFeeEuros?: number;
    rfidPaymentCardFeeEuros?: number;
    simCardMonthlyFeeEuros?: number;
    mediationAndBillingMonthlyFeePerNozzleEuros?: number;
    infrastructureUsageOneTimeFeePerNozzleEuros?: number;
    infrastructureUsageMonthlyFeePerNozzleEuros?: number;
    chargingServiceMonthlyFeeEuros?: number;
    chargingServiceMarginalCentsKwh?: number;
    gridDayTariffCentsKwh?: number;
    gridNightTariffCentsKwh?: number;
}

export interface ChargingAgreementCustomerForm {
    isCompany?: boolean;
    id?: number;
    customerName?: string;
    registryNumber?: string;
    vatRegistryNumber?: string;
    contactName?: string;
    contactPersonId?: number;
    phone?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    contactPersonCode?: string;
    address?: string;
    city?: string;
    zip?: string;
    county?: string;
}

export interface ChargingAgreementStationForm {
    serialNumber?: string;
    latitude?: string;
    longitude?: string;
    installationDate?: Date;
    installationExtraFeeEuros?: number;
    nozzleCount?: number;
    stationModelId?: number
}

export const NETO_COMPENSATION_ID = 3;

export function EditChargingAgreementModal(props: IEditChargingAgreementModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [form, setForm]: [CreateChargingAgreementForm, any] = useState({
        validated: false,
        customer: props.chargingAgreement ? {
            id: props.chargingAgreement.customerId,
            contactPersonId: props.chargingAgreement.contactPersonId,
            registryNumber: props.chargingAgreement.customerRegistryNumber
        } : undefined,
        hasChargerMaintenanceService: props.chargingAgreement ? props.chargingAgreement.hasChargerMaintenanceService : true,
        simCardCount: props.chargingAgreement ? (props.chargingAgreement.simCardCount ?? 0) : 0,
        rfidCardCount: props.chargingAgreement ?( props.chargingAgreement.rfidPaymentCardCount ?? 0) : 0,
        chargingStations: props.chargingAgreement ? props.chargingAgreement.chargingStations.map(station => ({
            ...station,
            stationModelId: station.model.id
        }) as ChargingAgreementStationForm) : [],
        meteringPointId: props.chargingAgreement?.meteringPoint.id ?? undefined,
        address: props.chargingAgreement?.address ?? undefined,
        city: props.chargingAgreement?.city ?? undefined,
        zip: props.chargingAgreement?.zip ?? undefined,
        externalChargingStationId: props.chargingAgreement?.externalChargingStationId ?? undefined,
        purchaseOptionId: props.chargingAgreement?.purchaseOptionId ?? undefined,
        serviceOptionId: props.chargingAgreement?.serviceOptionId ?? undefined,
        thirdPartyCompensationOptionId: props.chargingAgreement?.thirdPartyCompensationOptionId ?? undefined,
        contractPeriodId: props.chargingAgreement?.contractPeriodId ?? undefined,
        termsAndConditionsId: props.chargingAgreement?.termsAndConditionsId ?? undefined,
        startDate: props.chargingAgreement?.startDate ? new Date(props.chargingAgreement?.startDate) : undefined,
        endDate: props.chargingAgreement?.endDate ? new Date(props.chargingAgreement?.endDate) : undefined,
        chargerMaintenanceType: props.chargingAgreement?.chargerMaintenanceType ?? undefined,
        simCardPaymentType: props.chargingAgreement?.simCardPaymentType ?? undefined,
        installationFeeEuros: props.chargingAgreement?.installationFeeEuros ?? undefined,
        thirdPartyCompensationNetoFeeEuros: props.chargingAgreement?.thirdPartyCompensationNetoFeeEuros ?? undefined,
        maintenanceMonthlyFeeEuros: props.chargingAgreement?.maintenanceMonthlyFeeEuros ?? undefined,
        rfidPaymentCardFeeEuros: props.chargingAgreement?.rfidPaymentCardFeeEuros ?? undefined,
        simCardMonthlyFeeEuros: props.chargingAgreement?.simCardMonthlyFeeEuros ?? undefined,
        mediationAndBillingMonthlyFeePerNozzleEuros: props.chargingAgreement?.mediationAndBillingMonthlyFeePerNozzleEuros ?? undefined,
        infrastructureUsageOneTimeFeePerNozzleEuros: props.chargingAgreement?.infrastructureUsageOneTimeFeePerNozzleEuros ?? undefined,
        infrastructureUsageMonthlyFeePerNozzleEuros: props.chargingAgreement?.infrastructureUsageMonthlyFeePerNozzleEuros ?? undefined,
        chargingServiceMonthlyFeeEuros: props.chargingAgreement?.chargingServiceMonthlyFeeEuros ?? undefined,
        chargingServiceMarginalCentsKwh: props.chargingAgreement?.chargingServiceMarginalCentsKwh ?? undefined,
        gridDayTariffCentsKwh: props.chargingAgreement?.gridDayTariffCentsKwh ?? undefined,
        gridNightTariffCentsKwh: props.chargingAgreement?.gridNightTariffCentsKwh ?? undefined
    });
    const [pricing, setPricing] = useState<ChargingPricing>();

    const isEditModal = props.modalType === ModalType.EDIT;

    const validateSubmit = () => {
        if (validate()) {
            submit();
        }
    }

    const validateCustomer = () => {
        if (!form.customer?.registryNumber || !form.customer?.address || !form.customer?.email || !form.customer?.phone) return false;
        return true;
    }

    const validateMeteringPoint = () => {
        if(!form.meteringPointId || !form.externalChargingStationId) return false;
        return true;
    }

    const validateDevices = () => {
        if(!form.purchaseOptionId || !form.thirdPartyCompensationOptionId) return false;
        return true;
    }

    const validateServices = () => {
        if(!form.serviceOptionId) return false;

        if(form.hasChargerMaintenanceService && !form.chargerMaintenanceType) return false;
        return true;
    }

    const validateCargingStations = () => {
        return form.chargingStations.every(chargingStation => 
            chargingStation.serialNumber && chargingStation.stationModelId
        );
    }

    const validateAgreement = () => {
        if((!isEditModal && !form.pricingId) || !form.termsAndConditionsId || !form.contractPeriodId || !form.startDate) return false;
        return true;
    }

    const validate = () => {
        setForm({ ...form, validated: true });
        
        return validateCustomer() && validateMeteringPoint() && validateDevices() && 
            validateServices() && validateCargingStations() && validateAgreement();
    }

    const submit = async () => {
        try{
            setSaving(true);
            if(isEditModal){
                await ChargingAgreementsService.updateChargingAgreement(form, props.chargingAgreement!.id);
            }else{
                await ChargingAgreementsService.createChargingAgreement(form);
            }
            setSavingError(false);      
            setSaving(false);
            props.handleModalHide();
            props.onSuccess?.()
        }catch(e){
            console.error(e);
            setSaving(false);
            setSavingError(true);
        }
    };

    return (
        <Modal
            show={true}
            size={"xl"}
            dialogClassName="bg-light"
            onHide={props.handleModalHide}
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>
                    <span>
                        {
                            props.modalType === ModalType.CREATE ? "Laadimislepingu loomine" : "Laadimislepingu uuendamine"
                        }
                    </span>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <NewChargingAgreementData 
                    form={form}
                    setForm={setForm}
                    pricing={pricing}
                    setPricing={setPricing}
                    isEditModal={isEditModal}
                />
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.handleModalHide()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }
        </Modal>
    );
}
