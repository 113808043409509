/* eslint-disable react-hooks/exhaustive-deps */
import { ElectricityCustomerSettingsModalFormState, SettingsModalNavState } from "components/ElectrcityCustomers/modals/ElectricityCustomerSettingsModal";
import { ICustomer } from "components/ElectrcityCustomers/model";
import { ElectricityAgreementsService } from "components/ElectricityAgreements/ElectricityAgreements.service";
import { IElectricityAgreement } from "components/ElectricityAgreements/models";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { CustomersAgreementsTable } from "./CustomersAgreementsTable";
import { Button, Spinner } from "react-bootstrap";
import { CustomerInfo } from "./CustomerInfo";
import { BANK_ACCOUNT_NUMBER_REGEX_EXPRESSION } from "common/constants/regex";
import ModalContentBlock from "components/common/ModalContentBlock";

interface CustomerDetailsProps{
  customer : ICustomer;
  form: ElectricityCustomerSettingsModalFormState;
  setForm: Dispatch<SetStateAction<ElectricityCustomerSettingsModalFormState>>;
  setActiveAgreement: Dispatch<SetStateAction<IElectricityAgreement | undefined>>;
  navigate : (nextState : SettingsModalNavState) => void;
  invoiceRecipientIsInvalid: boolean;
}

export function CustomerDetails({customer, form, setForm, navigate, setActiveAgreement, invoiceRecipientIsInvalid} : CustomerDetailsProps){
  const [agreements, setAgreements] = useState<IElectricityAgreement[]>([])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    findActiveAgreements();
  }, [])

  const findActiveAgreements = async () => {
    setIsLoading(true);

    try{
        const response = await ElectricityAgreementsService.getActiveElectricityAgreements(customer.id);
        setAgreements(response);
    }catch(e){
      console.error(e)
    }finally{
      setIsLoading(false)
    }
  }

  const handleAgreementEdit = (agreement: IElectricityAgreement) => {
    setForm({
      ...form,
      agreement : {
        invoiceRecipientEmail: agreement.invoiceRecipientEmail,
        paidFromCustomersAdvance: agreement.paidFromCustomersAdvance
      }
    })
    setActiveAgreement(agreement);
    navigate(SettingsModalNavState.AGREEMENT)
  }

  const customerDetailsTitle = customer.isPerson ? "Isikuandmed" : "Ettevõtte andmed"
  const bankAccountNumberIsInvalid = form.bankAccountNumber ? !BANK_ACCOUNT_NUMBER_REGEX_EXPRESSION.test(form.bankAccountNumber) : false

  return (
    <>
      <ModalContentBlock title={customerDetailsTitle}>
        <CustomerInfo 
          title="Nimi"
          value={customer.name}
        />
        <CustomerInfo 
          title="Aadress"
          value={customer.address}
        />
        <CustomerInfo 
          title="EIC"
          value={customer.eic}
        />
        <CustomerInfo 
          title={customer.isPerson ? "Isikukood" : "Registrikood"}
          value={customer.registryNumber}
        />
        <Form noValidate>
          <div className="row mb-2">
            <div className="col-6">
                <Form.Label>Pangakonto number</Form.Label>
                <Form.Control
                    value={form.bankAccountNumber ?? ''}
                    type="text"
                    onChange={(val: any) => setForm({
                        ...form,
                        bankAccountNumber: val.target.value
                    })}
                    placeholder="EE909900123456789012"
                    isInvalid={bankAccountNumberIsInvalid}
                    isValid={!bankAccountNumberIsInvalid}
                />
                <Form.Control.Feedback className={'text-right'} type="invalid">Pangakonto number ei ole valiidne</Form.Control.Feedback>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
                <Form.Label>Arve saaja e-post</Form.Label>
                <Form.Control
                    value={form?.invoiceRecipientEmail ?? ''}
                    onChange={(e: any) => setForm({
                        ...form, 
                        invoiceRecipientEmail : e.target.value
                    })}
                    as="textarea"
                    rows={1}
                    isInvalid={invoiceRecipientIsInvalid}
                />
                <Form.Control.Feedback className={'text-right'} type="invalid">Vigane e-post!</Form.Control.Feedback>
            </div>
          </div>
          <div className="row mb-2 mt-2 d-flex align-items-center">
            <div className="col-6">
            <Form.Check
                id='check-use-group-invoicing'
                custom
                type="checkbox"
                className='checkbox-large'
                label='Gruppidel põhinev arveldamine'
                onChange={() => setForm({
                  ...form, 
                  isGroupInvoicingEnabled: !form.isGroupInvoicingEnabled
                })}
                checked={form.isGroupInvoicingEnabled}
            />
            </div>
          </div> 
          <div className="row mb-2 mt-2 d-flex align-items-center">
            <div className="col-6">
            <Form.Check
                id='check-is-factoring-client'
                custom
                type="checkbox"
                className='checkbox-large'
                label='Faktooringu klient'
                onChange={() => setForm({
                  ...form, 
                  isFactoringClient: !form.isFactoringClient
                })}
                checked={form.isFactoringClient}
            />
            </div>
          </div> 
          <div className="row mb-2 mt-2 d-flex align-items-center">
            <div className="col-6">
            <Form.Check
                id='check-receive-invoice-by-email'
                custom
                type="checkbox"
                className='checkbox-large'
                label='Soovin elektriarvet e-postiga'
                onChange={() => setForm({
                  ...form, 
                  receiveInvoiceByEmail: !form.receiveInvoiceByEmail
                })}
                checked={form.receiveInvoiceByEmail}
            />
            </div>
          </div> 
        </Form>
        {
          form.isGroupInvoicingEnabled && (
            <Button 
              variant="secondary"
              onClick={() => {
                navigate(SettingsModalNavState.INVOICE_GROUPS)
                setForm({
                  ...form,
                  validated: false,
                })
              }}
              type="button"
              className={"d-flex align-items-center mt-3"}
            >
                <span className='px-2'>Seadista arve gruppe</span>
            </Button>
          )
        }
      </ModalContentBlock>
      <ModalContentBlock title="Lepingud">
        {
          isLoading ? (
            <div className="text-center">
              <Spinner animation={"border"} size={"sm"} />
            </div>
          ) : (
            <CustomersAgreementsTable 
              agreements={agreements}
              handleEdit={handleAgreementEdit}
            />
          )
        }
      </ModalContentBlock>
    </>
  )
}