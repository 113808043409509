/* eslint-disable react-hooks/exhaustive-deps */
import { TermsAndConditionsService } from "components/TermsAndConditions/TermsAndConditions.service";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import moment from "moment";
import { ElectricityAgreementsService } from "components/ElectricityAgreements/ElectricityAgreements.service";
import { CreateChargingAgreementForm } from "components/ChargingAgreements/modals/EditChargingAgreementModal";

interface NewChargingAgreementDetailsFormProps {
    form: CreateChargingAgreementForm;
    setForm: (form: CreateChargingAgreementForm) => void;
}

export default function NewChargingAgreementDetailsForm(
    { form, setForm } : NewChargingAgreementDetailsFormProps
) {

    const [termsAndConditionsOptions, setTermsAndConditionsOptions] = useState<any[]>([]);
    const [contractPeriodOptions, setContractPeriodOptions] = useState<any[]>([]);

    useEffect(() => {
        getTermsAndConditions();
        getContractPeriods();
    }, []);

    const getTermsAndConditions = async () => {
        try{
            const res = await TermsAndConditionsService.getTermsAndConditions();
            setTermsAndConditionsOptions(
                res.content.map(c => ({ value: c.id, label: c.name }))
            );
        }catch(e){
            console.error(e);
        }
    }

    const getContractPeriods = async () => {        
        try{
            const res = await ElectricityAgreementsService.getElectricityContractPeriods();
        
            setContractPeriodOptions(
                res.map((c:any) => ({ value: c.id, label: c.name }))
            );
        }catch(e){
            console.error(e);
        }
    }

    const currentTermsAndConditionsOption = termsAndConditionsOptions.find(t => t.value === form.termsAndConditionsId) || null;
    const currentContractPeriod = contractPeriodOptions.find(p => p.value === form.contractPeriodId) || null;

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Tüüptingimused*</Form.Label>
                    <Select
                        options={termsAndConditionsOptions}
                        value={currentTermsAndConditionsOption}
                        onChange={(val: any) => setForm({
                            ...form,
                            termsAndConditionsId: val?.value
                        })}
                        className={'w-100 form-control form-select' + (!form.termsAndConditionsId && form.validated ? ' is-invalid' : '')}
                        placeholder='Vali tüüptingimus'
                        required
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>          
            </div>
            <div className="row mt-3">
                <div className="col-6">
                    <Form.Label>Lepingu periood*</Form.Label>
                    <Select
                        options={contractPeriodOptions}
                        value={currentContractPeriod}
                        onChange={(val: any) => setForm({
                            ...form,
                            contractPeriodId: val.value
                        })}
                        className={'w-100 form-control form-select' + (!form.contractPeriodId && form.validated ? ' is-invalid' : '')}
                        placeholder='Vali lepingu periood'
                        required
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                {
                    currentContractPeriod?.label === "Tähtajaline" ? (
                        <div className="col-6">
                            <Form.Label htmlFor="contract-end">Lepingu lõpp*</Form.Label>
                            <DatePicker
                                className={'d-block' + (form.endDate === undefined && form.validated ? ' invalid is-invalid' : '')}
                                value={form.endDate}
                                locale='et'
                                clearIcon={null}
                                calendarIcon={<CalendarIcon />}
                                format='dd.MM.yyyy'
                                minDate={moment(form.startDate).add(1, "d").toDate()}
                                onChange={(event: any) => {
                                    setForm({
                                        ...form, 
                                        endDate: event ? new Date(event) : undefined
                                    })
                                }}
                            />
                            <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    ) : null
                }
            </div>            
            <div className="row mt-3">
                <div className="col-12 col-md-6">
                    <Form.Label htmlFor="contract-start">Lepingu algus*</Form.Label>
                        <DatePicker
                            className={'d-block' + (form.startDate === undefined && form.validated ? ' invalid is-invalid' : '')}
                            value={form.startDate}
                            locale='et'
                            clearIcon={null}
                            calendarIcon={<CalendarIcon />}
                            format='dd.MM.yyyy'
                            onChange={(event: any) => {
                                if(!event){
                                    return;
                                }
                                const dayDiff = moment(form.endDate).diff(event, 'days', true)
                                setForm({
                                    ...form, 
                                    startDate: new Date(event),
                                    endDate: dayDiff < 1 ? moment(event).add(1, "d").toDate() : form.endDate
                                })
                            }}
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
        </>
    );
}