/* eslint-disable react-hooks/exhaustive-deps */
import { CreateChargingAgreementForm } from "components/ChargingAgreements/modals/EditChargingAgreementModal";
import { MeteringPointSelectOption } from "components/common/MeteringPointSelectionOption";
import { MeteringPoint } from "components/ElectricityAgreements/models";
import { getMeteringPointWarningMessage } from "helpers";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import MeteringPointService from "services/electricity/electricity-metering-points.service";

interface NewChargingAgreementMeteringPointFormProps {
    form: CreateChargingAgreementForm;
    setForm: (form: CreateChargingAgreementForm) => void;
    isEditModal: boolean;
}

export default function NewChargingAgreementMeteringPointForm(
    { form, setForm, isEditModal } : NewChargingAgreementMeteringPointFormProps
) {
    const [meteringPoints, setMeteringPoints] = useState<MeteringPoint[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        findMeteringPoints();
    }, []);

    const mapMeteringPointsToOptions = (mps : MeteringPoint[]) => {
        return mps.map((mp) => {
            let isDisabled = false;
            let warning = undefined;
            if(mp.relatedChargingAgreement){
                isDisabled = mp.relatedChargingAgreement.startDate === null;
                warning = getMeteringPointWarningMessage(
                    {
                        ...mp.relatedChargingAgreement,
                        isElectricity: false
                    }
                )
            }

            return {
                label: mp.eic, 
                value: {
                    ...mp,
                    warning
                },
                isDisabled
            }
        })
    }

    const findMeteringPoints = async () => {
        try{
            setError("");
            setIsLoading(true);
            let res;
            if (form.customer?.id) {
                res = await MeteringPointService.getMeteringPointsExistingCustomer(form.customer.id!);
            } else if (form.customer?.registryNumber) {
                const name = form.customer.isCompany ? form.customer.customerName : form.customer.firstName + ' ' + form.customer.lastName;
                res = await MeteringPointService.getMeteringPointsNewCustomer(form.customer.registryNumber, name || '');
            }
            setMeteringPoints(
                res.meteringPoints
            )
        }catch(e){
            console.error(e);
            setError("Mõõtepunktide pärmine ebaõnnestus!")
        }finally{
            setIsLoading(false)
        }
    }

    const getFilteredMeteringPoints = (searchTerm: string, callback: Function) => {
        if(!meteringPoints.length){
          return callback([]);
        }
    
        let filteredMeteringPoints = meteringPoints.filter(mp => 
          mp.id !== form.meteringPointId
        );
    
        if (!searchTerm) {
          return mapMeteringPointsToOptions(filteredMeteringPoints);
        }
    
        filteredMeteringPoints = filteredMeteringPoints.filter(mp => 
          mp.eic.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        );
    
        callback(
          mapMeteringPointsToOptions(filteredMeteringPoints)
        )
    }

    const meteringPointOptions = mapMeteringPointsToOptions(meteringPoints);

    const currentOption = meteringPointOptions.find(mp => mp.value.id === form.meteringPointId);

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Mõõtepunkt*</Form.Label>
                        <AsyncSelect 
                            loadOptions={getFilteredMeteringPoints}
                            defaultOptions={meteringPointOptions}
                            noOptionsMessage={() => "Valikud puuduvad"}
                            isClearable={!isEditModal}
                            onChange={(e: any) => setForm({
                                ...form,
                                meteringPointId: e?.value.id ?? undefined
                            })}
                            loadingMessage={() => "..."}
                            placeholder={"Vali mõõtepunkt..."}
                            value={currentOption}
                            components={{Option: MeteringPointSelectOption}}
                            isLoading={isLoading}
                            isDisabled={isLoading || isEditModal}
                        /> 
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                {
                    form.meteringPointId ? (
                        <div className="col-12 col-md-6">
                            <Form.Label>Laadimisjaama NOOM ID</Form.Label>
                            <Form.Control
                                value={form.externalChargingStationId ?? ""}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    externalChargingStationId: e.target.value
                                })}
                                type="text"
                                required
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    ): null
                }
            </div>
            { error ? <div className="text-danger">{error}</div> : null }
            { form.validated && !form.meteringPointId ? <div className="text-danger mt-1">Mõõtepunkt valimata!</div> : null }
        </>
    );
}