import axios from "axios";
import { extractData, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";

async function findChargingStationModels() {
  return axios.get(`${apiService.getApiUrl()}/charging/station-models/admin`)
    .then(handleResponse).then(extractData);
}

export const ChargingStationModelService = {
  findChargingStationModels
};