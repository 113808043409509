/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { CreateChargingAgreementForm } from "components/ChargingAgreements/modals/EditChargingAgreementModal";
import { ChargingPricingsService } from "components/ChargingPricings/ChargingPricings.service";
import { ChargingPricing } from "components/ChargingPricings/models";

interface NewChargingAgreementPricingFormProps {
    form: CreateChargingAgreementForm;
    setForm: (form: CreateChargingAgreementForm) => void;
    setPricing: (pricing: ChargingPricing) => void;
    isEditModal: boolean;
}

export default function NewChargingAgreementPricingForm(
    { form, setForm, setPricing, isEditModal } : NewChargingAgreementPricingFormProps
) {
    const [pricings, setPricings] = useState<any[]>([]);

    useEffect(() => {
        findPricings();
    }, []);

    const findPricings = async () => {
      try{
        const pricings = await ChargingPricingsService.findChargingPricings();
        setPricings(
            pricings
        );
      }catch(e){
        console.error(e)
      }
    }

    const pricingOptions = pricings.map((opt : any) => ({ value: opt.id, label: opt.name }));
    const currentPricingOption = pricingOptions.find(opt => opt.value === form.pricingId);

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Hinnakiri*</Form.Label>
                        <Select
                            required={!isEditModal}
                            options={pricingOptions}
                            value={currentPricingOption ?? null}
                            onChange={(opt: any) => {
                              setPricing(
                                pricings.find(pricing => pricing.id === opt.value)
                              )
                              setForm({
                                ...form,
                                pricingId: opt.value
                              })
                            }}
                            placeholder='Vali hinnakiri'
                            className={'w-100 form-control form-select' + (!form.pricingId && form.validated ? ' is-invalid' : '')}
                        />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div> 
            </div>
        </>
    );
}