import axios from "axios";
import { extractData, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";

async function findChargingPurchaseOptions() {
  return axios.get(`${apiService.getApiUrl()}/charging/purchase-options/admin`)
    .then(handleResponse).then(extractData);
}

export const ChargingPurchaseOptionService = {
  findChargingPurchaseOptions
};