/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { FileComponent } from "./FileComponent";
import { FileDropzone } from "./FileDropzone";
import { FilePreview } from "./FilePreview";

export interface UploadedFile {
    fileName?: string;
    file?: File;
    fileUrl?: string;
    uploaded?: boolean;
}

interface FileUploadFormGroupProps {
  acceptedMimeTypes: Record<string, string[]>;
  isInvalid?: boolean;
  uploadedFile?: UploadedFile;
  label: string;
  onImageDrop: (file: File) => void;
  onImageRemove: () => void;
  getFile: () => void;
  className?: string;
  labelClassName?: string;
  dropzoneContainerClassName?: string
}

export function FileUploadFormGroup(props : FileUploadFormGroupProps){

  useEffect(() => {
    if(!props?.uploadedFile?.fileUrl && props?.uploadedFile?.fileName){
        props.getFile()
    }
  }, [props.uploadedFile])
  
  let dropzoneContainerStyle = props.isInvalid ? 'is-invalid col' : 'col';
  if(props.dropzoneContainerClassName){
    dropzoneContainerStyle = props.dropzoneContainerClassName;
  }

  return (
    <Form.Group
          className={props.className ?? "row"}
      >
          <Form.Label 
              className={props.labelClassName ?? "col-3"}
          >
              <span className="text-nowrap">{props.label}</span>
          </Form.Label>
          <div className={dropzoneContainerStyle}>
            {
                  (!props?.uploadedFile?.file && !props.uploadedFile?.fileUrl) && (
                      <>
                          <FileDropzone 
                              onDrop={(file) => props.onImageDrop(file)}
                              acceptedMimeTypes={props.acceptedMimeTypes}
                              className={`w-100 mt-3 ${props.isInvalid ? "file-upload-group--invalid" : ""}`}
                          />
                          {props.isInvalid && (
                              <div className="text-right text-danger text-small-font-size">
                                  <small>Pilt on kohustuslik</small>
                              </div>
                          )}
                      </>
                  )
              }
              {
                  (props?.uploadedFile?.fileUrl && props?.uploadedFile?.fileName) && (
                    <>
                      <FileComponent 
                        fileName={props.uploadedFile.fileName}
                        onRemove={() => props.onImageRemove()}
                      />
                      <FilePreview 
                          src={props?.uploadedFile?.fileUrl}
                          className="mt-2"
                      />
                    </>
                  )
              }
          </div>
          <div className="col-3"/>
      </Form.Group> 
  )
}