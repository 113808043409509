import {IPageResponse} from "../../common/interfaces/IPageResponse";
import {ModalType} from "../../common/common-models";
import {IPageRequest} from "../../common/interfaces/IPageRequest";

export interface IElectricityPackagesState {
    electricityPackages: IPageResponse<IElectricityPackage> | null;
    pageRequest: IPageRequest<IElectricityPackageSortFields>;
    isEditModalVisible: boolean;
    isDeleteModalVisible: boolean;
    modalType: ModalType;
    electricityPackage?: IElectricityPackage;
}

export interface IElectricityPackage {
    id: number;
    nameEt: string;
    nameEn: string;
    descriptionEt: string;
    descriptionEn: string;
    marketPricePackage: boolean;
    pdfProductDescriptionOnInvoice?: string;
    externalProductDescriptionOnInvoice?: string;
    jointInvoicePackage: boolean;
    basePrice: number;
    basePriceTextEt: string;
    basePriceTextEn: string;
    basePriceDescriptionEt: string;
    basePriceDescriptionEn: string;
    monthlyFee?: number;
    contractPeriodId: number;
    contractEndDate?: string;
    greenEnergy: boolean;
    marginals: Array<IElectricityPackageMarginal>;
    active: boolean;
    highlighted: boolean;
    orderNumber: number;
    contractTitle?: string;
    endDate?: string;
    pdfFooterDisclaimerPerson?: string;
    pdfFooterDisclaimerCompany?: string;
    dateModified: string;
    type: ElectricityPackageType;
    showToPrivateCustomer: boolean;
    showToBusinessCustomer: boolean;
    terms: { id: number, name: string};
}

export enum ElectricityPackageType {
    PRODUCTION = 'PRODUCTION',
    CONSUMPTION = 'CONSUMPTION'
}

export const ELECTRICITY_PACKAGE_TYPE_MAP: Record<ElectricityPackageType, string> = {
    PRODUCTION: 'Tootmine',
    CONSUMPTION: 'Tarbimine',
};

export interface IElectricPackageTableProps {
    electricityPackages: IPageResponse<IElectricityPackage>;
    openElectricityPackageModal: (modalType: ModalType, electricityPackage?: IElectricityPackage) => void;
    openDeleteElectricityPackageModal: (electricityPackage: IElectricityPackage) => void;
}

export interface IElectricityPackageMarginal {
    id: number;
    packageID: number;
    price: number;
    maxValue: number;
    dateCreated: string;
}

export interface IElectricityPackageFormMarginal {
    id?: number;
    price?: number;
    maxValue?: number;
}

export enum IElectricityPackageSortFields {
    ID = "ID",
    DATE_MODIFIED = "DATE_MODIFIED"
}

export interface IElectricityPackagesSearchBarProps {
    openElectricityPackageModal: (modalType: ModalType, electricityPackage?: IElectricityPackage) => void;
}

export interface IElectricityPackageModalProps {
    modalType: ModalType;
    handleModalHide: () => void;
    editingElectricityPackage?: IElectricityPackage;
    loadElectricityPackages: () => void;
}

export interface IElectricityPackageForm {
    nameEt: string;
    nameEn?: string;
    descriptionEt: string;
    descriptionEn?: string;
    marketPricePackage?: boolean;
    jointInvoicePackage?: boolean;
    basePrice?: number;
    basePriceTextEt?: string;
    basePriceTextEn?: string;
    basePriceDescriptionEt: string;
    basePriceDescriptionEn?: string;
    monthlyFee?: number;
    contractPeriodId?: number;
    contractEndDate?: string;
    greenEnergy: boolean;
    active: boolean;
    highlighted: boolean;
    orderNumber?: number;
    contractTitle?: string;
    pdfFooterDisclaimerPerson?: string;
    pdfFooterDisclaimerCompany?: string;
    termsAndConditions?: { value: number, label: string };
    termsAndConditionsId?: number;
    showToPrivateCustomer: boolean;
    showToBusinessCustomer: boolean;
    marginals: Array<IElectricityPackageFormMarginal>;
    pdfProductDescriptionOnInvoice?: string;
    externalProductDescriptionOnInvoice?: string;
}