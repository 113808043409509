import axios from "axios";
import { extractData, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";

async function findChargingServiceOptions() {
  return axios.get(`${apiService.getApiUrl()}/charging/service-options/admin`)
    .then(handleResponse).then(extractData);
}

export const ChargingServiceOptionService = {
  findChargingServiceOptions
};