import { MeteringPoint } from "components/ElectricityAgreements/models";

export interface ChargingAgreement {
  id: number;
  contractNumber: string;
  customerId: number;
  customerName: string;
  customerRegistryNumber: string;
  contactPersonId: number;
  startDate: string;
  endDate?: string;
  status: ChargingAgreementStatus;
  contractPeriod: string;
  contractPeriodId: number;
  address?: string;
  city?: string;
  zip?: string;
  hasChargerMaintenanceService: boolean;
  chargerMaintenanceType?: ChargingBillingCycle;
  rfidPaymentCardCount?: number;
  simCardCount?: number;
  simCardPaymentType?: ChargingBillingCycle;
  meteringPoint: MeteringPoint;
  externalChargingStationId: string;
  purchaseOptionId: number;
  serviceOptionId: number;
  thirdPartyCompensationOptionId: number;
  termsAndConditionsId: number;
  chargingStations: ChargingStation[];
  installationFeeEuros: number;
  thirdPartyCompensationNetoFeeEuros: number;
  maintenanceMonthlyFeeEuros: number;
  rfidPaymentCardFeeEuros: number;
  simCardMonthlyFeeEuros: number;
  mediationAndBillingMonthlyFeePerNozzleEuros: number;
  infrastructureUsageOneTimeFeePerNozzleEuros: number;
  infrastructureUsageMonthlyFeePerNozzleEuros: number;
  chargingServiceMonthlyFeeEuros: number;
  chargingServiceMarginalCentsKwh: number;
  gridDayTariffCentsKwh: number;
  gridNightTariffCentsKwh: number;
  dateCreated: string;
}

export interface SimplifiedChargingAgreement {
  id: number;
  name: string;
  startDate: string;
}

export interface ChargingStation {
  id: number;
  serialNumber: string;
  latitude?: string;
  longitude: string;
  installationDate?: string;
  installationExtraFeeEuros?: number;
  nozzleCount?: number;
  model: ChargingStationModel;
}

export enum ChargingAgreementStatus {
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED'
}

export const CHARGING_AGREEMENT_STATUS_MAP: Record<ChargingAgreementStatus, string> = {
  ACTIVE: 'Aktiivne',
  ENDED: 'Lõppenud',
  PENDING: 'Ootel',
  REJECTED: 'Tagasilükatud',
  CANCELED: 'Tühistatud'
};

export enum ChargingAgreementSortFields {
  ID = "ID",
  DATE_MODIFIED = "DATE_MODIFIED",
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  DATE_CREATED = 'DATE_CREATED'
}

export interface ChargingAgreementFilters {
  search?: string;
  status?: ChargingAgreementStatus;
}

export enum ChargingPurchaseOptionType {
  PURCHASE = 'PURCHASE',
  RENT = 'RENT',
  FULL_SERVICE_RENT = 'FULL_SERVICE_RENT',
  EXISTING = 'EXISTING',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER'
}

export interface ChargingPurchaseOption {
  id: number;
  name: string;
  description?: string;
  type: ChargingPurchaseOptionType
}

export enum ChargingServiceOptionType {
  PUBLIC = 'PUBLIC',
  SEMI_PUBLIC = 'SEMI_PUBLIC',
  PRIVATE_AUTHENTICATED = 'PRIVATE_AUTHENTICATED',
  PRIVATE_NON_AUTHENTICATED = 'PRIVATE_NON_AUTHENTICATED'
}

export interface ChargingServiceOption {
  id: number;
  name: string;
  description?: string;
  type: ChargingServiceOptionType
}

export enum ChargingThirdPartyCompensationOptionType {
  FIXED = 'FIXED',
  TARIFF = 'TARIFF',
  NETO = 'NETO'
}

export interface ChargingThirdPartyCompensationOption {
  id: number;
  name: string;
  description?: string;
  type: ChargingThirdPartyCompensationOptionType
}

export interface ChargingStationModel {
  id: number;
  name: string;
  description?: string;
  rentalMonthlyFeeEuros: number;
  priceEuros: number;
}

export enum ChargingBillingCycle {
  YEARLY = 'YEARLY',
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY'
}

export const chargingBillingCycleOptions = [
  {value: ChargingBillingCycle.YEARLY, label : "Kord aastas"},
  {value: ChargingBillingCycle.QUARTERLY, label : "Kord kvartalis"},
  {value: ChargingBillingCycle.MONTHLY, label : "Kord kuus"}
]