import React from "react";
import { OptionTypeBase } from "react-select";

export function MeteringPointSelectOption(
  props: OptionTypeBase,
){
  return (
    <div 
      className={`invoice-group-form__metering-point-select ${props.isDisabled ? 'invoice-group-form__metering-point-select--disabled' : ''}`}
      onClick={() => props.setValue(props.data)}
    >
      <div>{props.label}</div>
      {
        props.value.address ? (
          <div>{props.value.address}</div>
        ) : null
      }
      {props.value.warning}
    </div>
  )
}