import axios from "axios";
import { extractData, handleResponse } from "helpers/apiResponseHelper";
import { apiService } from "services/api.service";

async function findChargingThirdPartyCompensationOptions() {
  return axios.get(`${apiService.getApiUrl()}/charging/third-party-compensation-options/admin`)
    .then(handleResponse).then(extractData);
}

export const ChargingThirdPartyCompensationOptionService = {
  findChargingThirdPartyCompensationOptions
};