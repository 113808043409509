import AddressAutocomplete from "components/AddressAutocomplete/AddressAutocomplete";
import { CreateChargingAgreementForm } from "components/ChargingAgreements/modals/EditChargingAgreementModal";
import { ICustomerContactPerson } from "components/CustomerContactPersons/models";
import React from "react";
import { Form, Spinner } from "react-bootstrap";
import Select from "react-select";

interface NewChargingAgreementAgreementCustomerFormProps {
    isCompany: boolean;
    form: CreateChargingAgreementForm;
    setForm: (form: CreateChargingAgreementForm) => void;
    isFormDisabled: boolean;
    onSearch: () => void;
    contactPersons: ICustomerContactPerson[];
    customerLoading: boolean;
    customerLoaded: boolean;
    isEditModal: boolean;
}

export default function NewChargingAgreementAgreementCustomerForm(
    { isCompany, form, setForm, isFormDisabled, onSearch, contactPersons, customerLoading, customerLoaded, isEditModal }:
    NewChargingAgreementAgreementCustomerFormProps
) {
    const currentContactPersons = contactPersons.filter(c => c.contactName === form.customer?.contactName).map(c => ({ value: c.id, label: c.contactName }));

    const isSearchDisabled = !form.customer?.registryNumber?.length || isEditModal;

    function onEnterKey(e: React.KeyboardEvent<HTMLDivElement>){        
        if(isSearchDisabled)
            return;

        if(e.keyCode === 13 || e.key === 'Enter'){
            e.preventDefault();
            onSearch()
        }
    }

    return (
        <>
            <div className="row" onKeyDown={onEnterKey}>
                <div className="col-12 col-md-6">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Isiku / registrikood</span>
                    </Form.Label>
                    <div className="input-group">
                        <Form.Control
                            value={form.customer?.registryNumber}
                            disabled={isEditModal}
                            type="number"
                            onChange={(e) => setForm({ 
                                ...form, 
                                customer: {
                                    ...form.customer,
                                    registryNumber: e.target.value
                                }
                            })}
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-primary rounded d-flex align-items-center"
                                type="button"
                                disabled={isSearchDisabled}
                                onClick={() => {
                                    if(!isSearchDisabled)
                                        onSearch()
                                }}
                            >
                                { customerLoading ? <Spinner size="sm" animation="border" /> : <i className="icon icon__search d-inline-block"></i>}
                                
                            </button>
                        </div>
                    </div>
                </div>
                { isCompany ? <div className="col-12 col-md-6 mt-3 mt-lg-0">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">{ 'Käibemaksukohuslase number' }</span>
                    </Form.Label>
                    <Form.Control
                        value={form.customer?.vatRegistryNumber}
                        disabled={true}
                        type="text"
                    />
                </div> : null }
            </div>
            { customerLoaded ? <><div className="row mt-3">
                {
                    !form.customer?.id && !isCompany ?
                    <>
                        <div className="col-12 col-md-6">
                            <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                                <span className="text-nowrap">Eesnimi*</span>
                            </Form.Label>                    
                            <Form.Control
                                value={form.customer?.firstName}
                                type="text"
                                onChange={(val: any) => setForm({
                                    ...form,
                                    customer: {
                                        ...form.customer,
                                        firstName: val.target.value
                                    }
                                })}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                                <span className="text-nowrap">Perenimi*</span>
                            </Form.Label>                    
                            <Form.Control
                                value={form.customer?.lastName}
                                type="text"
                                onChange={(val: any) => setForm({
                                    ...form,
                                    customer: {
                                        ...form.customer,
                                        lastName: val.target.value
                                    }
                                })}
                            />
                        </div>                        
                    </> : 
                    <div className="col-12 col-md-6">
                        <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                            <span className="text-nowrap">{ isCompany ? 'Ettevõtte nimi' : 'Nimi' }{ !form.customer?.id ? '*' : ''}</span>
                        </Form.Label>
                        <Form.Control
                            value={form.customer?.customerName}
                            disabled={!!form.customer?.id}
                            type="text"
                            onChange={(val: any) => setForm({
                                ...form,
                                customer: {
                                    ...form.customer,
                                    customerName: val.target.value
                                }
                            })}
                        />
                    </div>
                }
                { isCompany && contactPersons.length ? 
                    <div className="col-12 col-md-6">
                        <Form.Label>
                            Kontaktisik*
                        </Form.Label>
                        <Select
                            options={contactPersons.map(c => ({ value: c.id, label: c.contactName }))}
                            value={ currentContactPersons.length ? currentContactPersons[0] : undefined }
                            onChange={(val: any) => setForm({
                                ...form,
                                customer: {
                                    ...form.customer,
                                    contactName: val?.label,
                                    contactPersonId: val?.value,
                                    phone: contactPersons.find(c => c.id === val?.value)?.phone || contactPersons.find(c => c.id === val?.value)?.mobileNumber,
                                    email: contactPersons.find(c => c.id === val?.value)?.contactEmail,
                                    contactPersonCode: contactPersons.find(c => c.id === val?.value)?.personalIdentificationCode,
                                }
                            })}
                            className={'w-100 px-0 form-control form-select'}
                            placeholder='Vali kontaktisik'
                            required
                        />
                    </div>
                    : null
                }
                { isCompany && !form.customer?.id ?
                    <div className="col-12 col-md-6">
                        <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                            <span className="text-nowrap">Kontakti isikukood*</span>
                        </Form.Label>
                        <Form.Control
                            value={form.customer?.contactPersonCode}
                            type="text"
                            onChange={(val: any) => setForm({
                                ...form,
                                customer: {
                                    ...form.customer,
                                    contactPersonCode: val.target.value
                                }
                            })}
                        />
                    </div>

                : null} 
            </div>
            { isCompany && !form.customer?.id ? <div className="row mt-3">
                <div className="col-12 col-md-6">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Kontakti eesnimi*</span>
                    </Form.Label>                    
                    <Form.Control
                        value={form.customer?.firstName}
                        type="text"
                        onChange={(val: any) => setForm({
                            ...form,
                            customer: {
                                ...form.customer,
                                firstName: val.target.value
                            }
                        })}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Kontakti perenimi*</span>
                    </Form.Label>                    
                    <Form.Control
                        value={form.customer?.lastName}
                        type="text"
                        onChange={(val: any) => setForm({
                            ...form,
                            customer: {
                                ...form.customer,
                                lastName: val.target.value
                            }
                        })}
                    />
                </div>            
            </div> : null}
            <div className="row mt-3">
                <div className="col-12 col-md-6 mt-3 mt-lg-0">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Kontakttelefon*</span>
                    </Form.Label>
                    <Form.Control
                        required
                        value={form.customer?.phone}
                        disabled={isFormDisabled}
                        onChange={(e) => setForm({ 
                            ...form, 
                            customer: {
                                ...form.customer,
                                phone: e.target.value 
                            }
                        })}
                        type="tel"
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className={"col-12 col-md-6 mt-3 mt-lg-0"}>
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">E-post*</span>
                    </Form.Label>                    
                    <Form.Control
                        required
                        value={form.customer?.email}
                        disabled={isFormDisabled}
                        onChange={(e) => setForm({ 
                            ...form, 
                            customer: {
                                ...form.customer,
                                email: e.target.value 
                            }
                        })}
                        type="email"
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-xxl-6 mt-3">
                    <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                        <span className="text-nowrap">Aadress*</span>
                    </Form.Label>
                    <AddressAutocomplete
                        address={form.customer?.address}
                        setAddress={(address) => setForm({
                            ...form,
                            customer: {
                                ...form.customer,
                                address: address.fullAddress || address.address,
                                city: address.city,
                                zip: address.zip,
                                county: address.county
                            }
                        })}
                        disabled={isFormDisabled}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div></> : null}
        </>
    )
}