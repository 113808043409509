import { ModalType } from 'common/common-models';
import React, {PureComponent} from 'react';
import { Button } from 'react-bootstrap';
import { CHARGING_AGREEMENT_STATUS_MAP, ChargingAgreementFilters, ChargingAgreementStatus } from './models';
import Select from 'react-select';

interface ChargingAgreementsSearchBarProps {
    openChargingAgreementModal: (modalType: ModalType) => void;
    filters: ChargingAgreementFilters;
    onFilter: (filter: ChargingAgreementFilters) => void;
}

const statuses = [
    { value: ChargingAgreementStatus.ACTIVE, label: CHARGING_AGREEMENT_STATUS_MAP[ChargingAgreementStatus.ACTIVE] },
    { value: ChargingAgreementStatus.ENDED, label: CHARGING_AGREEMENT_STATUS_MAP[ChargingAgreementStatus.ENDED] },
    { value: ChargingAgreementStatus.PENDING, label: CHARGING_AGREEMENT_STATUS_MAP[ChargingAgreementStatus.PENDING] },
    { value: ChargingAgreementStatus.REJECTED, label: CHARGING_AGREEMENT_STATUS_MAP[ChargingAgreementStatus.REJECTED] },
    { value: ChargingAgreementStatus.CANCELED, label: CHARGING_AGREEMENT_STATUS_MAP[ChargingAgreementStatus.CANCELED] },
];

const selectStyle = {
    indicatorSeparator: () => (
        {
            backgroundColor: 'red',
            width: '50px',
        }
    ),
};

export class ChargingAgreementsSearchBar extends PureComponent<ChargingAgreementsSearchBarProps, any> {

    onInputChange = (inputEvent: any) => {
        this.props.onFilter({
            search: inputEvent.target.value
        });
    };

    onStatusChange = (value: any) => {
        this.props.onFilter({
            status: value?.value
        });
    }

    render() {
        return (
            <div className="bar-position">
                <h2 className="text-light">
                    Laadimislepingud
                </h2>
                <div className="card mb-5 shadow">
                    <div className="card-body">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <input
                                placeholder="Klient / Lepingu number"
                                className='search-bar__input'
                                onChange={this.onInputChange}
                                value={this.props.filters.search || ''}
                            />
                            <Select
                                style={selectStyle}
                                className='mw-160-px mr-2 customer-status__select'
                                value={statuses.find(s => s.value === this.props.filters.status) || null}
                                onChange={this.onStatusChange}
                                options={statuses}
                                isClearable
                                placeholder='Vali staatus'
                            />
                            <div className="d-flex flex-nowrap ml-auto">
                                <Button className="ml-auto d-flex align-items-center"
                                        onClick={() => this.props.openChargingAgreementModal(ModalType.CREATE)}
                                        variant={"primary"}>
                                    <i className="icon--16 icon__add d-flex mr-1"/>
                                    <span>Lisa uus</span>
                                </Button>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        )
    }
}