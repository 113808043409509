/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {Button, Form, InputGroup, Modal, Spinner} from "react-bootstrap";
import {EditPetrolStationConfirmModal} from "./EditPetrolStationConfirmModal";
import {PetrolStationsService} from "../PetrolStations.service";
import {IPetrolStationConfirmModalProps, IPetrolStationForm} from "../models";
import {ModalType} from "../../../common/common-models";
import { FileUploadFormGroup } from 'components/FileUpload/FileUploadFormGroup';
import { IProduct } from 'components/Product/models';

const ACCEPTED_MIME_TYPES = {
    'image/svg+xml': ['.svg'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/webp': ['.webp']
  };

export function EditPetrolStationModal(props: IPetrolStationConfirmModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [validated, setValidated]: [boolean, any] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible]: [boolean, any] = useState(false);
    const [form, setForm]: [IPetrolStationForm, any] = useState({
        nameEt: props.editingPetrolStation ? props.editingPetrolStation.nameEt : "",
        externalId: props.editingPetrolStation && props.editingPetrolStation.externalId ? props.editingPetrolStation.externalId : "",
        latitude: props.editingPetrolStation && props.editingPetrolStation.latitude ? props.editingPetrolStation.latitude.toString() : "",
        longitude: props.editingPetrolStation && props.editingPetrolStation.longitude ? props.editingPetrolStation.longitude.toString() : "",
        enabledFeatures: props.editingPetrolStation ? props.editingPetrolStation.enabledFeatures : [],
        descriptionEt: props.editingPetrolStation && props.editingPetrolStation.descriptionEt ? props.editingPetrolStation.descriptionEt : "",
        locationEt: props.editingPetrolStation && props.editingPetrolStation.locationEt ? props.editingPetrolStation.locationEt : "",
        image : {
            fileName : props.editingPetrolStation && props.editingPetrolStation.originalFileName ? props.editingPetrolStation.originalFileName : undefined,
            uploaded: false
        }
    });
    const [petrolStationProducts, setPetrolStationProducts] = useState<IProduct[]>([])
    const [isPetrolStationProductsLoading, setIsPetrolStationProductsLoading] = useState(false);

    const handleCheckboxClick = (id: number, isChecked: boolean) => {
        setForm({
            ...form,
            enabledFeatures: isChecked ? 
                [...form.enabledFeatures, id] : 
                form.enabledFeatures.filter(itemId => itemId !== id)
        });
    };

    const validateForm = () => {
        if (form.nameEt.trim().length === 0)
            return false;
        /*
         if (form.descriptionEt.trim().length === 0)
            return false;
        if (form.locationEt.trim().length === 0)
            return false;
        */
        if (form.latitude.trim().length === 0 ||
                (form.latitude.trim().length > 0 && form.latitude.match("^[\\+-]?[0-9]{1,3}?[.,]?[0-9]{1,7}$") === null))
            return false;
        if (form.longitude.trim().length === 0 ||
                (form.longitude.trim().length > 0 && form.longitude.match("^[\\+-]?[0-9]{1,3}?[.,]?[0-9]{1,7}$") === null))
            return false;
        /*
        if(!form.image?.file)
            return false;*/
        return true;
    };

    const validateSubmit = () => {
        setValidated(false);
        const isValidForm = validateForm();
        setValidated(true);
        if (isValidForm) {
            setIsConfirmModalVisible(true);
        } else {
            setSaving(false);
        }
    };

    const handleSubmit = async () => {
        setSaving(true);
        setSavingError(false);

        try{
            let response;
            if (props.modalType === ModalType.CREATE) {
                response = await PetrolStationsService.postPetrolStation(form);
            } else {
                response = await PetrolStationsService.putPetrolStation(props.editingPetrolStation!.id, form);
            }

            if(form?.image?.file && form.image?.uploaded){
                await PetrolStationsService.uploadPetrolStationsImage(form.image?.file, response.id);
            }

            setTimeout(() => {
                props.handleModalHide();
            }, 300);

            props.loadPetrolStations();
        }catch(e: any){
            console.error('Saving failed', e);
            setSavingError(true);
        }finally{
            setSaving(false);
        }
    };

    const getImageFile = async () => {
        const res = await PetrolStationsService.getPetrolStationsImage(
            props.editingPetrolStation?.id!
        );

        setForm({
            ...form,
            image: {
                ...form.image,
                fileUrl: res.data,
                uploaded: false
            }
        })
    }

    const onImageDrop = (file: File) => {
      setForm({
        ...form,
        image : {
            file,
            fileUrl: window.URL.createObjectURL(file),
            fileName: file.name,
            uploaded: true
        }
      })
    }

    const onImageRemove = () => {
      setForm({
        ...form,
        image: {
            fileName: undefined,
            file: undefined,
            fileUrl: undefined,
            uploaded: false
        }
      })
    }

    const getStationProducts = async (petrolStationId: number) => {
        try{
            setIsPetrolStationProductsLoading(true);
            const res = await PetrolStationsService.getStationProducts(petrolStationId);
            setPetrolStationProducts(res);
        }catch(e){
            console.error(e);
        }finally{
            setIsPetrolStationProductsLoading(false);
        }
    };

    useEffect(() => {
        if(props.editingPetrolStation){
            getStationProducts(props.editingPetrolStation.id);
        }
    }, [])

    return (
        <Modal
            show={true}
            size={"lg"}
            onHide={props.handleModalHide}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {
                        props.modalType === ModalType.CREATE &&
                        <span>Tankla lisamine</span>
                    }
                    {
                        props.modalType === ModalType.EDIT &&
                        <span>Tankla andmete muutmine</span>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e: any) => e.preventDefault()}
                      id={"petrol-station-form"}
                      className={'mt-1 row align-self-center m-0'}>
                    <Form.Group controlId={'form-station-name'} className="row col-12 p-0 m-0">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-2'}>
                            <span className="text-nowrap">Tankla nimetus</span>
                        </Form.Label>
                        <div className='col-12 col-lg-9 p-0'>
                            <Form.Control
                                required
                                minLength={1}
                                value={form.nameEt}
                                onChange={(e) => setForm({
                                    ...form,
                                    nameEt: e.target.value
                                })}
                                disabled={saving}
                                type="text"
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <Form.Group controlId={'form-station-name'} className="row col-12 p-0 m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-2'}>
                            <span className="text-nowrap">Tankla kirjeldus</span>
                        </Form.Label>
                        <div className='col-12 col-lg-9 p-0'>
                            <Form.Control 
                                minLength={1}
                                value={form.descriptionEt}
                                onChange={(e) => setForm({
                                    ...form,
                                    descriptionEt: e.target.value
                                })}
                                disabled={saving}
                                as="textarea"
                                rows={6}
                                />
                        </div>
                    </Form.Group>
                    <Form.Group controlId={'form-station-external-id'} className="row col-12 p-0 m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-2'}>
                            <span className="text-nowrap">Noom ID</span>
                        </Form.Label>
                        <Form.Control 
                            className="col-12 col-lg-4"
                            value={form.externalId}
                            onChange={(e) => setForm({
                                ...form,
                                externalId: e.target.value
                            })}
                            disabled={saving}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group controlId={'form-station-name'} className="row col-12 p-0 m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-2'}>
                            <span className="text-nowrap">Tankla asukoht</span>
                        </Form.Label>
                        <div className='col-12 col-lg-9 p-0'>
                            <Form.Control 
                                minLength={1}
                                value={form.locationEt}
                                onChange={(e) => setForm({
                                    ...form,
                                    locationEt: e.target.value
                                })}
                                disabled={saving}
                                type="text"
                            />
                        </div>
                    </Form.Group>
                    <Form.Group controlId={'form-station-lat'} className="row col-12 p-0 m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-2'}>
                            <span className="text-nowrap">Asukoht kaardil</span>
                        </Form.Label>
                        <InputGroup className="col-12 col-lg-4 px-0 ">
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    id="latitude-label">Lat</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control 
                                required 
                                className="border-radius-right"
                                minLength={1}
                                pattern={"^[\\+-]?[0-9]{1,3}?[.,]?[0-9]{1,7}$"}
                                value={form.latitude}
                                placeholder={"--.-------"}
                                onChange={(e) => setForm({
                                    ...form,
                                    latitude: e.target.value ? e.target.value.replace(',', '.') : e.target.value
                                })}
                                disabled={saving}
                                type="text"
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId={'form-station-lng'} className="row col-12 p-0 m-0 mt-2">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12 col-lg-2'}>
                        </Form.Label>
                        <InputGroup className="col-12 col-lg-4 px-0">
                            <InputGroup.Prepend>
                                <InputGroup.Text className={"border-radius-right"}
                                                 id="latitude-label">Lng</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control 
                                required 
                                pattern={"^[\\+-]?[0-9]{1,3}?[.,]?[0-9]{1,7}$"}
                                value={form.longitude}
                                placeholder={"--.-------"}
                                onChange={(e) => setForm({
                                    ...form,
                                    longitude: e.target.value ? e.target.value.replace(',', '.') : e.target.value
                                })
                                }
                                disabled={saving}
                                type="text"
                            />
                        </InputGroup>
                        <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <FileUploadFormGroup 
                        acceptedMimeTypes={ACCEPTED_MIME_TYPES}
                        uploadedFile={form.image}
                        label="Pilt*"
                        onImageDrop={(file) => onImageDrop(file)}
                        onImageRemove={() => onImageRemove()}
                        getFile={() => getImageFile()}
                        className="row col-12 p-0 m-0 mt-3"
                        labelClassName="mb-0 align-self-center m-1 text-start col-12 col-lg-2"
                        dropzoneContainerClassName={`col-12 col-lg-9 p-0`}
                    />
                    <Form.Group controlId={'form-station-features'} className="row col-12 p-0 m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center m-1 text-start col-12'}>
                            <span className="text-nowrap">Omadused</span>
                        </Form.Label>
                        <InputGroup className="col-12 row m-0">
                            {
                                props.stationFeatures.map(stationFeature => {
                                    return (
                                        <Form.Check inline
                                                    key={stationFeature.id}
                                                    className="col-4 p-2 m-0 form-check-label-black"
                                                    label={stationFeature.nameEt}
                                                    type="checkbox"
                                                    id={`station-feature-${stationFeature.id}`}
                                                    checked={form.enabledFeatures.indexOf(stationFeature.id) !== -1 }
                                                    onChange={(e: any) => {
                                                        handleCheckboxClick(stationFeature.id, e.target.checked);
                                                    }}
                                        />
                                    )
                                })
                            }
                        </InputGroup>
                    </Form.Group>
                    {props.modalType === ModalType.EDIT ? (
                        <Form.Group controlId={'form-station-products'} className="row col-12 p-0 m-0 mt-3">
                            <Form.Label className={'mb-0 align-self-center m-1 text-start col-12'}>
                                <span className="text-nowrap">Kütused</span>
                            </Form.Label>
                            <InputGroup className="col-12 row m-0">
                                {isPetrolStationProductsLoading && (
                                    <Spinner size="sm" animation='grow'/>
                                )}
                                {
                                    petrolStationProducts.map(product => (
                                        <Form.Group className='col-4 p-2 m-0' key={product.id}>
                                            <Form.Check
                                                inline
                                                className=" form-check-label-black"
                                                disabled={true}
                                                type="checkbox"
                                                id={`station-product-${product.id}`}
                                                checked={true}
                                            />
                                            <Form.Label className={''}>
                                                <span className="text-nowrap">{product.nameEt}</span>
                                            </Form.Label>
                                        </Form.Group>
                                    ))
                                }
                                {
                                    !petrolStationProducts.length && !isPetrolStationProductsLoading && (
                                        <small className='m-1 text-gray-700'>Kütused puuduvad...</small>
                                    )
                                }
                            </InputGroup>
                        </Form.Group>
                    ) : null }
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.handleModalHide()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }

            {
                validated && isConfirmModalVisible &&
                    <EditPetrolStationConfirmModal
                        petrolStationName={form.nameEt}
                        handleConfirmSubmit={() => {
                            setIsConfirmModalVisible(false);
                            handleSubmit()
                        }}
                        handleConfirmReject={() => {
                            setSaving(false);
                            setIsConfirmModalVisible(false);
                        }}
                        modalType={props.modalType!}/>
            }
        </Modal>
    );
}
